import { takeEvery, takeLatest, put, call } from "redux-saga/effects"
import {
  startBlockUI,
  stopBlockUI
} from "common/components/BlockUIConnected/actions"
import { replace } from "connected-react-router/immutable"
import { LOAD_PAGE } from "./constants"
import { pageLoaded, formSubmit } from "./actions"
import {
  errorNotification,
  addNotification
} from "../../../common/components/NotificationStack/actions"
import api from "../../api"

function* onError(response) {
  return yield put(
    errorNotification(response, "Error Loading Menu type Information")
  )
}

function* onSuccess(message) {
  return yield put(addNotification({ message, type: "success" }))
}

function* loadPageSaga({ payload: { id } }) {
  if (id && id !== "new") {
    const response = yield call(api.menuTypes.get, { id })
    if (response.err) return yield call(onError, response)
    const { menuType } = response.data
    return yield put(pageLoaded({ menuType }))
  }
  yield put(pageLoaded({ menuType: {} }))
}

function* handleFormSaga(action) {
  yield put(startBlockUI())
  let menuType = action.payload.toJS()
  console.log(menuType)
  const { id } = menuType
  if (id && id !== "new") {
    const response = yield call(api.menuTypes.update, { id }, menuType)
    if (response.err) return yield call(onError, response)
    menuType = response.data.menuType // eslint-disable-line
  } else {
    const response = yield call(api.menuTypes.create, menuType)
    if (response.err) return yield call(onError, response)
    menuType = response.data.menuType // eslint-disable-line
  }
  yield call(
    onSuccess,
    `Menu type was successfully ${id ? "updated" : "created"}`
  )
  yield put(formSubmit.success())
  yield put(stopBlockUI())
  yield put(replace(`/builders/menu-types/${menuType.id}`))
}

export default function* defaultSaga() {
  yield takeLatest(LOAD_PAGE, loadPageSaga)
  yield takeEvery(formSubmit.REQUEST, handleFormSaga)
}
