/**
 *
 * RestaurantsPage
 *
 */

import withPagination from "common/components/withPagination"
import PagePagination from "common/components/PagePagination"
import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Query } from "@apollo/client/react/components"
import restaurantsQuery from "./query"
import { createStructuredSelector } from "reselect"
import PageError from "common/components/PageError"
import PageLoader from "common/components/PageLoader/index"
import { compose } from "redux"
import format from "date-fns/format"
import { forEach, debounce, flattenDeep, uniq, map } from "lodash"
import queryString from "query-string"
import { withStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableFooter from "@material-ui/core/TableFooter"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import Menu from "@material-ui/core/Menu"
import { Link } from "react-router-dom"
import MUILink from "@material-ui/core/Link"

import {
  Container,
  RestaurantCompletion,
  RestaurantCompletionInfo,
  RestaurantCompletionErrorInfo
} from "./parts"

import Page from "admins/components/Page"
import { makeSelectUser } from "common/selectors"
import MarketFilter from "common/components/MarketFilter"
import withMarketParam from "common/components/withMarketParam"
import withSearchParam from "common/components/withSearchParam"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import { Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import { Link as RouterLink } from "react-router-dom"
const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    width: "100%"
  },
  button: {
    marginBottom: "20px"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  listTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    paddingBottom: "10px"
  },
  inactiveRestaurant: {
    backgroundColor: "rgba(255,0,0,0.2)"
  },
  link: {
    wordBreak: "break-word !important"
  }
})

class ContactDropdown extends React.Component {
  state = {
    anchorEl: null
  }

  openContactDropdown = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  closeContactDropdown = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { anchorEl } = this.state
    return (
      <div>
        <Button
          variant="contained"
          onClick={this.openContactDropdown}
          size={"small"}
        >
          Contact
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.closeContactDropdown}
        >
          <MenuItem>
            <a href={`tel:${this.props.phone}`}>{this.props.phone}</a>
          </MenuItem>
          <MenuItem>
            <a href={`mailto:${this.props.email}`}>{this.props.email}</a>
          </MenuItem>
        </Menu>
      </div>
    )
  }
}

const ImageLinksDialog = ({ open, onCloseDialog, restaurant, classes }) => {
  const restaurantImages = map(restaurant.logo, (link, name) => ({
    name,
    link
  }))
  return (
    <Dialog open={open} onClose={onCloseDialog}>
      <DialogContent>
        <Grid container direction={"column"} spacing={4}>
          <Grid item>
            <Typography variant={"h6"} gutterBottom color={"secondary"}>
              Restaurant Logo
            </Typography>
            {restaurantImages
              .filter((image) => image.name !== "__typename")
              .map((image, key) => (
                <div key={key}>
                  <Typography variant={"body1"}>
                    <b>
                      <i>
                        {image.name.charAt(0).toUpperCase() +
                          image.name.substring(1)}
                      </i>
                    </b>
                  </Typography>
                  <DialogContentText gutterBottom classes={classes.link}>
                    <MUILink
                      color="inherit"
                      href={image.link}
                      target={"_blank"}
                      classes={classes.link}
                    >
                      {image.link}
                    </MUILink>
                  </DialogContentText>
                </div>
              ))}
          </Grid>
          <Grid item>
            {restaurant.trucks.map((truck) => {
              const truckImages = map(truck.image, (link, name) => ({
                name,
                link
              }))
              return (
                <>
                  <Typography variant={"h6"} gutterBottom color={"secondary"}>
                    Truck Logo - {truck.name}
                  </Typography>
                  {truckImages
                    .filter((image) => image.name !== "__typename")
                    .map((image, key) => (
                      <div key={key}>
                        <Typography variant={"body1"}>
                          <b>
                            <i>
                              {image.name.charAt(0).toUpperCase() +
                                image.name.substring(1)}
                            </i>
                          </b>
                        </Typography>
                        <DialogContentText gutterBottom>
                          <MUILink
                            color="inherit"
                            href={image.link}
                            target={"_blank"}
                          >
                            {image.link}
                          </MUILink>
                        </DialogContentText>
                      </div>
                    ))}
                </>
              )
            })}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

class RestaurantsPage extends React.Component {
  static breadcrumbs = () => [
    {
      name: "Restaurants",
      url: "/restaurants"
    }
  ]

  constructor(props, context) {
    super(props, context)
    this.state = {
      searchValue: props.search || "",
      isOpenImageLinksDialog: false,
      restaurantDialog: null
    }
  }

  debouncedChangePath = debounce(this.props.onChangeSearch, 1000)

  onSearchChange = (e) => {
    const { value } = e.target
    this.setState(
      {
        searchValue: value
      },
      () => {
        this.debouncedChangePath({ search: value })
      }
    )
  }

  query = (props) => queryString.parse(props.location.search)

  renderRestaurantCompletion(restaurant) {
    const info = []
    forEach(restaurant.completion, (item) => {
      if (!item.required) info.push(item.name)
    })
    return (
      <RestaurantCompletion>
        {info.length === 0 && (
          <RestaurantCompletionInfo>All Good</RestaurantCompletionInfo>
        )}
        {info.map((text, index) => (
          <RestaurantCompletionErrorInfo key={index}>
            {text}
          </RestaurantCompletionErrorInfo>
        ))}
      </RestaurantCompletion>
    )
  }

  devicesInfo(restaurant) {
    if (restaurant.userDevices.length === 0) {
      return <em>No Devices</em>
    }
    return restaurant.userDevices.map((d, i) => (
      <Tooltip
        key={i}
        title={<pre>{JSON.stringify(d, null, 2)}</pre>}
        placement="top"
      >
        <span>
          {d.appVersion || "not set"}
          {i !== restaurant.userDevices.length - 1 && <span>, </span>}
        </span>
      </Tooltip>
    ))
  }

  getFoodTypes = (restaurant) =>
    uniq(
      flattenDeep(
        restaurant.menus.records.map((menu) =>
          menu.foodTypes.map((foodType) => foodType.name)
        )
      )
    )

  openImageLinksDialog = (restaurant) => () =>
    this.setState({
      isOpenImageLinksDialog: true,
      restaurantDialog: restaurant
    })
  closeImageLinksDialog = () =>
    this.setState({
      isOpenImageLinksDialog: false,
      restaurantDialog: null
    })

  renderRestaurantList({ restaurants, total }) {
    const { classes } = this.props
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell size="small">Logo</TableCell>
            <TableCell size="small">Completion</TableCell>
            <TableCell size="small">Name</TableCell>
            <TableCell size="small">Cuisine</TableCell>
            <TableCell size="small">Menus</TableCell>
            <TableCell size="small">Locations</TableCell>
            <TableCell size="small">Devices</TableCell>
            <TableCell size="small">Stripe</TableCell>
            <TableCell size="small" />
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.restaurantDialog && (
            <ImageLinksDialog
              classes={classes}
              open={this.state.isOpenImageLinksDialog}
              onCloseDialog={this.closeImageLinksDialog}
              restaurant={this.state.restaurantDialog}
            />
          )}
          {restaurants.map((restaurant) => (
            <TableRow
              key={restaurant.id}
              className={restaurant.active ? null : classes.inactiveRestaurant}
            >
              <TableCell size="small">
                <img
                  src={restaurant.logo.small}
                  alt=""
                  style={{ width: "50px" }}
                  onClick={this.openImageLinksDialog(restaurant)}
                />
              </TableCell>
              <TableCell size="small">
                {this.renderRestaurantCompletion(restaurant)}
              </TableCell>
              <TableCell size="small">
                {restaurant.name}{" "}
                {restaurant.installedApp && <span>(App Installed)</span>}
                <br />
                Signed Up on{" "}
                {format(restaurant.createdAt, "EEE MM/dd hh:mm aaaa")}
              </TableCell>
              <TableCell size="small">
                {this.getFoodTypes(restaurant).map((foodType, i) => (
                  <div key={i}>&#9679; {foodType}</div>
                ))}
              </TableCell>
              <TableCell size="small">
                {restaurant.menus.total} menus, {restaurant.items.total} items
              </TableCell>
              <TableCell size="small">{restaurant.locations.total}</TableCell>
              <TableCell size="small">{this.devicesInfo(restaurant)}</TableCell>
              <TableCell size="small">
                {restaurant.stripeLink && (
                  <a
                    href={restaurant.stripeLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Stripe (Connect)
                  </a>
                )}
                <br />
                {restaurant.stripeCustomerLink && (
                  <a
                    href={restaurant.stripeCustomerLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Stripe (Customer)
                  </a>
                )}
              </TableCell>
              <TableCell size="small">
                <Button
                  variant="contained"
                  component={Link}
                  to={`/restaurants/${restaurant.id}`}
                  size={"small"}
                  className={classes.button}
                >
                  View
                </Button>
                <ContactDropdown
                  phone={restaurant.phone}
                  email={restaurant.email}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <PagePagination
              offset={this.props.offset}
              perPage={this.props.perPage}
              perPageOptions={[30, 60, 120]}
              total={total}
              onOffsetChange={this.props.onOffsetChange}
              label="Restaurants per page"
              onPerPageChange={this.props.onChangePerPage}
              hotkeys
              align="center"
            />
          </TableRow>
        </TableFooter>
      </Table>
    )
  }

  renderItems = ({ restaurants: { records, total } }) => {
    const { classes } = this.props
    return (
      <Paper className={classes.root}>
        <Container>
          <div
            className={
              "flex flex-col lg:flex-row flex-wrap lg:justify-between gap-x-8 gap-y-4 my-8 items-stretch lg:items-center"
            }
          >
            <div className={"px-4"}>
              <MarketFilter
                fullWidth
                value={this.props.marketId}
                onChange={this.props.onChangeMarketId}
              />
            </div>
            <div className={"px-4"}>
              <TextField
                fullWidth
                placeholder="Search..."
                value={this.state.searchValue}
                onChange={this.onSearchChange}
              />
            </div>
            <Button
              component={RouterLink}
              className="ml-4"
              variant="outlined"
              color="default"
              to={`/trucks-review`}
            >
              Trucks Review Dashboard
            </Button>
            <Button
              component={RouterLink}
              className="ml-4"
              variant="outlined"
              color="default"
              to={`/account-deletion-requests`}
            >
              Deletion Requests
            </Button>
          </div>
          {this.renderRestaurantList({
            restaurants: records,
            total
          })}
        </Container>
      </Paper>
    )
  }

  breadcrumbs = () => RestaurantsPage.breadcrumbs()

  renderContent = () => (
    <Query
      fetchPolicy="cache-and-network"
      query={restaurantsQuery}
      variables={{
        offset: this.props.offset,
        limit: this.props.perPage,
        nameSearch: this.props.search,
        marketId: this.props.marketId
      }}
    >
      {({ data, previousData, error }) => {
        if (error) return <PageError message={error.message} />
        if (!(data ?? previousData)?.restaurants) return <PageLoader />

        return this.renderItems(data ?? previousData)
      }}
    </Query>
  )

  render() {
    if (!this.props.user) return null
    return (
      <Page
        breadcrumbs={this.breadcrumbs}
        render={this.renderContent}
        title="Manage Restaurants"
      />
    )
  }
}

RestaurantsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  ...PagePagination.injectedProps
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser()
})

export default compose(
  connect(mapStateToProps),
  withMarketParam,
  withSearchParam,
  withPagination({ defaultPerPage: 30 })
)(withStyles(styles)(RestaurantsPage))
