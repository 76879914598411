import React from "react"
import { push } from "connected-react-router/immutable"
import { connect } from "react-redux"
import queryString from "query-string"
import PropTypes from "prop-types"
import hoistNonReactStatics from "hoist-non-react-statics"

const withPagination = ({ defaultPerPage = 10 }) => (WrappedComponent) => {
  const klass = class extends React.PureComponent {
    static propTypes = {
      dispatch: PropTypes.func.isRequired,
      location: PropTypes.object.isRequired
    }

    onOffsetChange = (offset) => {
      const newQuery = queryString.stringify({
        ...this.query(),
        offset
      })
      this.props.dispatch(push(`${this.props.location.pathname}?${newQuery}`))
    }

    onChangePerPage = (perPage) => {
      const newQuery = queryString.stringify({
        ...this.query(),
        perPage,
        offset: 0
      })
      this.props.dispatch(push(`${this.props.location.pathname}?${newQuery}`))
    }

    query = () => queryString.parse(this.props.location.search)

    render() {
      const query = this.query()
      const offset = query.offset ? parseInt(query.offset, 10) : 0
      const perPage = query.perPage
        ? parseInt(query.perPage, 10)
        : defaultPerPage
      return (
        <WrappedComponent
          offset={offset}
          perPage={perPage}
          onChangePerPage={this.onChangePerPage}
          onOffsetChange={this.onOffsetChange}
          {...this.props}
        />
      )
    }
  }
  hoistNonReactStatics(klass, WrappedComponent)
  return connect()(klass)
}

export default withPagination
