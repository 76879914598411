/**
 *
 * BuildersPage
 *
 */

import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { compose } from "redux"

import injectSaga from "admins/utils/injectSaga"
import injectReducer from "admins/utils/injectReducer"
import makeSelectBuildersPage from "./selectors"
import { loadPage } from "./actions"
import reducer from "./reducer"
import saga from "./saga"
import { push } from "connected-react-router/immutable"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import PlaceIcon from "@material-ui/icons/Place"
import FoodIcon from "@material-ui/icons/RestaurantMenu"
import HelpIcon from "@material-ui/icons/Help"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import ShowChartSharpIcon from "@material-ui/icons/ShowChartSharp"
import SpaceIcon from "@material-ui/icons/SpaceBar"
import GroupIcon from "@material-ui/icons/GroupWork"
import Filter1Icon from "@material-ui/icons/Filter1"
import SettingsIcon from "@material-ui/icons/SettingsOutlined"
import AttachmentIcon from "@material-ui/icons/FolderOpen"
import RefundIcon from "@material-ui/icons/Reply"
import NotificationsIcon from "@material-ui/icons/Notifications"
import TriggerConfigIcon from "@material-ui/icons/Settings"
import TemplatesIcon from "@material-ui/icons/QuestionAnswer"
import Divider from "@material-ui/core/Divider"
import styled from "styled-components"
import CopyMenuDialogButton from "./CopyMenuDialog/CopyMenuDialogButton"
import MakeMetabaseLinkButton from "./MakeMetabaseLinkDialog/MakeMetabaseLinkButton"

import Paper from "@material-ui/core/Paper"
import Page from "admins/components/Page"

const PaperContainer = styled(Paper)`
  padding: 20px;
`
class BuildersPage extends React.Component {
  static breadcrumbs = () => [
    {
      name: "Builders",
      url: "/builders"
    }
  ]

  componentDidMount() {
    this.props.dispatch(loadPage())
  }

  breadcrumbs = () => BuildersPage.breadcrumbs()

  renderContent = () => (
    <div className="row">
      <div className="col">
        <PaperContainer>
          <List>
            <Divider />
            <ListItem
              button
              onClick={() => this.props.dispatch(push("/builders/menu-types"))}
            >
              <ListItemIcon>
                <FoodIcon />
              </ListItemIcon>
              <ListItemText primary="Menu Types" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() => this.props.dispatch(push("/builders/food-types"))}
            >
              <ListItemIcon>
                <FoodIcon />
              </ListItemIcon>
              <ListItemText primary="Food Types And Categories" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() =>
                this.props.dispatch(push("/builders/catering-categories"))
              }
            >
              <ListItemIcon>
                <FoodIcon />
              </ListItemIcon>
              <ListItemText primary="Catering Categories" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() =>
                this.props.dispatch(push("/builders/food-type-pages"))
              }
            >
              <ListItemIcon>
                <FoodIcon />
              </ListItemIcon>
              <ListItemText primary="Food type pages" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() => this.props.dispatch(push("/builders/blog-posts"))}
            >
              <ListItemIcon>
                <AttachmentIcon />
              </ListItemIcon>
              <ListItemText primary="Blog posts" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() =>
                this.props.dispatch(push("/builders/organizer-page-hints"))
              }
            >
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText primary="Organizer Page Hints" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() => this.props.dispatch(push("/builders/lots"))}
            >
              <ListItemIcon>
                <SpaceIcon />
              </ListItemIcon>
              <ListItemText primary="Lots" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() => this.props.dispatch(push("/builders/lot-groups"))}
            >
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="Lot Groups" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() => this.props.dispatch(push("/builders/markets"))}
            >
              <ListItemIcon>
                <PlaceIcon />
              </ListItemIcon>
              <ListItemText primary="Markets" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() => this.props.dispatch(push("/builders/coupons"))}
            >
              <ListItemIcon>
                <Filter1Icon />
              </ListItemIcon>
              <ListItemText primary="Coupons" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() => this.props.dispatch(push("/builders/app-config"))}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="App Config" />
            </ListItem>
            <Divider />
            <CopyMenuDialogButton>
              <ListItem button>
                <ListItemIcon>
                  <FileCopyIcon />
                </ListItemIcon>
                <ListItemText primary="Copy Menu" />
              </ListItem>
            </CopyMenuDialogButton>
            <Divider />
            <MakeMetabaseLinkButton>
              <ListItem button>
                <ListItemIcon>
                  <ShowChartSharpIcon />
                </ListItemIcon>
                <ListItemText primary="Metabase Dashboard Link" />
              </ListItem>
            </MakeMetabaseLinkButton>
            <Divider />
            <ListItem
              button
              onClick={() =>
                this.props.dispatch(push("/builders/uploaded-assets"))
              }
            >
              <ListItemIcon>
                <AttachmentIcon />
              </ListItemIcon>
              <ListItemText primary="Uploaded Assets" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() => this.props.dispatch(push("/refunds"))}
            >
              <ListItemIcon>
                <RefundIcon />
              </ListItemIcon>
              <ListItemText primary="Refunds" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() =>
                this.props.dispatch(push("/builders/notifications"))
              }
            >
              <ListItemIcon>
                <NotificationsIcon />
              </ListItemIcon>
              <ListItemText primary="Truck Notifications" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() =>
                this.props.dispatch(push("/builders/credit-trigger-configs"))
              }
            >
              <ListItemIcon>
                <TriggerConfigIcon />
              </ListItemIcon>
              <ListItemText primary="Truck Credits Config" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() =>
                this.props.dispatch(push("/builders/reply-templates"))
              }
            >
              <ListItemIcon>
                <TemplatesIcon />
              </ListItemIcon>
              <ListItemText primary="Reply Templates" />
            </ListItem>
          </List>
        </PaperContainer>
      </div>
    </div>
  )

  render() {
    return (
      <Page
        breadcrumbs={this.breadcrumbs}
        page={this.props.page}
        render={this.renderContent}
        title="Builders"
      />
    )
  }
}

BuildersPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired
}

const mapStateToProps = createStructuredSelector({
  page: makeSelectBuildersPage()
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({
  key: "buildersPage",
  reducer
})
const withSaga = injectSaga({ key: "buildersPage", saga })

export default compose(withReducer, withSaga, withConnect)(BuildersPage)
