import _ from "lodash"
import config from "common/config"
import request, {
  toQueryString,
  postJson,
  putJson,
  patchJson,
  deleteJson
} from "./request"

function addHost(path) {
  return config.host + path
}

function makePath(path, { replacements, params }) {
  if (replacements) {
    if (!(typeof replacements === "object")) {
      throw new Error(
        `Replacements need to be object, ${typeof replacements} provided for ${path}`
      )
    }
    _.each(replacements, (value, name) => {
      if (path.includes(`:${name}`)) {
        path = path.replace(`:${name}`, value) // eslint-disable-line
      } else {
        throw new Error(`Can't find :${name} in ${path}`)
      }
    })
    if (path.includes(":")) {
      throw new Error(`Not all replacements were provided: ${path}`)
    }
  }

  let query = ""
  if (params) {
    query = `?${toQueryString(params)}`
  }
  return `${addHost(path)}${query}`
}

function resolvePathArgs(path, args) {
  const shouldHaveReplacements = path.includes(":")
  let replacements
  let data
  let options

  if (shouldHaveReplacements) {
    replacements = args[0] // eslint-disable-line
    data = args[1] // eslint-disable-line
    options = args[2] || {}
    if (!replacements) {
      throw new Error(`Please provide attributes for path: ${path}`)
    }
  } else {
    data = args[0] // eslint-disable-line
    options = args[1] || {}
  }

  return {
    shouldHaveReplacements,
    data,
    options,
    replacements
  }
}

function makeGetPath(path, args) {
  const {
    replacements,
    data: params,
    shouldHaveReplacements
  } = resolvePathArgs(path, args)
  return makePath(path, {
    replacements: shouldHaveReplacements && replacements,
    params
  })
}

export function get(path) {
  const curry = (...args) => {
    const { options } = resolvePathArgs(path, args)
    return request(makeGetPath(path, args), options)
  }
  curry.getPath = (args) => makeGetPath(path, args)
  return curry
}

export function post(path) {
  return (...args) => {
    const {
      replacements,
      data,
      options,
      shouldHaveReplacements
    } = resolvePathArgs(path, args)
    const result = makePath(path, {
      replacements: shouldHaveReplacements && replacements
    })
    return postJson(result, data, options)
  }
}

export function put(path) {
  return (...args) => {
    const {
      replacements,
      data,
      options,
      shouldHaveReplacements
    } = resolvePathArgs(path, args)
    const result = makePath(path, {
      replacements: shouldHaveReplacements && replacements
    })
    return putJson(result, data, options)
  }
}

export function patch(path) {
  return (...args) => {
    const {
      replacements,
      data,
      options,
      shouldHaveReplacements
    } = resolvePathArgs(path, args)
    const result = makePath(path, {
      replacements: shouldHaveReplacements && replacements
    })
    return patchJson(result, data, options)
  }
}

// delete is a reserved word
export function destroy(path) {
  return (...args) => {
    const {
      replacements,
      data,
      options,
      shouldHaveReplacements
    } = resolvePathArgs(path, args)
    const result = makePath(path, {
      replacements: shouldHaveReplacements && replacements
    })
    return deleteJson(result, data, options)
  }
}
