import styled from "styled-components"

export const ActionsContainer = styled.div`
  padding: 0 0 20px 0;
  text-align: left;
`

export const Container = styled.section`
  padding: 30px;
`

export const ListButtonContainer = styled.section`
  padding-top: 10px;
`

export const ActionButton = styled.div`
  display: inline-block;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`
