/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import Rollbar from "common/utils/Rollbar"

Rollbar.init()

// Import all the third party stuff
import React from "react"
import "sanitize.css/sanitize.css"
import "./css/diffy.css"
// eslint-disable-next-line
import formActionSaga from "common/pkg/redux-form-saga"
import confirmDialog from "admins/utils/confirmDialog"
import requestValue from "admins/utils/requestValue"

// Import root app
import App from "admins/containers/App"

// Load the favicon, the manifest.json file and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions, import/no-webpack-loader-syntax */
import "!file-loader?name=[name].[ext]!./admins.manifest"
import "!file-loader?name=[name].[ext]!images/favicon.ico"
import "!file-loader?name=[name].[ext]!images/favicon-48.png"
import "!file-loader?name=[name].[ext]!images/favicon-96.png"
import "!file-loader?name=[name].[ext]!images/favicon-144.png"
import "!file-loader?name=[name].[ext]!images/favicon-192.png"
import "!file-loader?name=[name].[ext]!images/logo512.png"
/* eslint-enable import/no-unresolved, import/extensions, import/no-webpack-loader-syntax */

import configureStore from "./configureStore"

// Import CSS reset and Global Styles
import theme, { muiTheme, jss } from "./theme"

import ConnectedApp from "common/ConnectedApp"
import logEventConfig from "admins/utils/logEventConfig"
import DashboardTypeContext from "common/DashboardTypeContext"

window.__authTokenName = "X-Admin-Token" // eslint-disable-line
window.__authEmailName = "X-Admin-Email" // eslint-disable-line

export default function AdminsApp() {
  return (
    <ConnectedApp
      authTokenName={"X-Admin-Token"}
      authEmailName={"X-Admin-Email"}
      configureStore={configureStore}
      jss={jss}
      theme={theme}
      muiTheme={muiTheme}
      logEventConfig={logEventConfig}
      confirmDialog={confirmDialog}
      requestValue={requestValue}
    >
      <DashboardTypeContext.Provider value={"admins"}>
        <App />
      </DashboardTypeContext.Provider>
    </ConnectedApp>
  )
}
