import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles"
import { compose } from "redux"

const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: white;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
  ${({ theme }) => theme.media.sm`padding: 5px 10px; font-size: 14px;`};
`

const Section = styled.div`
  a,
  span {
    cursor: pointer;
    text-decoration: none;
    ${({ active }) => active && "cursor: default;"} ${({ active }) =>
      active && "color: #999;"};
  }
`

const SectionSeparator = styled.span`
  margin-left: 8px;
  margin-right: 8px;
  color: #999 !important;
`

const styles = (theme) => ({
  coloredText: {
    color: theme.palette.primary.main
  }
})

class Breadcrumbs extends React.PureComponent {
  render() {
    const { sections, classes } = this.props
    return (
      <SectionContainer className="breadcrumbs">
        {sections.map((section, i) => {
          const active = i === sections.length - 1
          return (
            <Section key={i} active={active}>
              {active ? (
                <span>{section.name}</span>
              ) : (
                <Link to={section.url} className={classes.coloredText}>
                  {section.name}
                </Link>
              )}
              {!active && <SectionSeparator>/</SectionSeparator>}
            </Section>
          )
        })}
      </SectionContainer>
    )
  }
}

Breadcrumbs.propTypes = {
  sections: PropTypes.array,
  classes: PropTypes.object
}

export default compose(withStyles(styles, { withTheme: true }))(Breadcrumbs)
