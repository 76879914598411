/*
 *
 * BlockUIConnected actions
 *
 */

import { START_BLOCK, STOP_BLOCK } from "./constants"

export function startBlockUI() {
  return {
    type: START_BLOCK
  }
}

export function stopBlockUI() {
  return {
    type: STOP_BLOCK
  }
}
