import React from "react"
import MakeMetabaseLinkDialog from "./index"
import { connect } from "react-redux"
import { compose } from "redux"
import { useMetabaseMakePublicLink } from "./types/MetabaseMakePublicLink"
import getMutationErrors from "common/utils/getMutationErrors"

const MakeMetabaseLinkButton = ({
  children
}: {
  children: React.ReactElement
}) => {
  const [metabaseMakePublicLink] = useMetabaseMakePublicLink({})
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = async ({ lotIds }: { lotIds: number[] }) => {
    if (!lotIds) {
      throw new Error("Lot ids are missing")
    }

    const response = await metabaseMakePublicLink({
      variables: {
        id: 13,
        type: "dashboard",
        params: JSON.stringify({
          lot_id: lotIds
        })
      }
    })

    const errors = getMutationErrors(response!)

    if (errors) {
      return errors
    }
    const url = response!.data!.metabaseMakePublicLink!.url!
    window.open(url, "_blank")
  }

  return (
    <>
      {React.cloneElement(children, {
        onClick: handleClickOpen
      })}
      <MakeMetabaseLinkDialog
        onClose={handleClose}
        open={open}
        onSubmit={onSubmit}
      />
    </>
  )
}

export default compose(connect())(MakeMetabaseLinkButton)
