import React from "react"
import PropTypes from "prop-types"
import TextField from "@material-ui/core/TextField"
import { Field } from "redux-form/immutable"

class InnerFormTextField extends React.Component {
  static propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    description: PropTypes.any,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    meta: PropTypes.object,
    onChange: PropTypes.func,
    InputProps: PropTypes.object,
    children: PropTypes.any
  }

  static defaultProps = {
    meta: {},
    InputProps: {}
  }

  repositionCursor = (e) => {
    if (e.target.value.length === e.target.selectionStart) {
      e.persist()
      setTimeout(() => {
        e.target.selectionStart = e.target.value.length // reposition
      }, 10)
    }
  }

  UNSAFE_componentWillMount() {
    this.fixNormalize = /Android.*Chrome/.test(window.navigator.userAgent)
  }

  onChange = (e) => {
    if (this.fixNormalize) {
      this.repositionCursor(e)
    }
    this.props.input.onChange(e)
    if (this.props.onChange) {
      this.props.onChange(e)
    }
  }

  render() {
    const {
      input,
      label,
      description,
      placeholder,
      type,
      InputProps,
      meta: { touched, error },
      ...rest
    } = this.props
    const showError = touched && !!error
    return (
      <TextField
        error={showError}
        placeholder={placeholder}
        label={label}
        value={input.value}
        onChange={this.onChange}
        name={input.name}
        type={type}
        helperText={showError ? error : description}
        margin="normal"
        InputProps={{
          inputProps: {
            onBlur: input.onBlur,
            ...(InputProps.inputProps || {})
          },
          ...InputProps
        }}
        {...rest}
      />
    )
  }
}

export default class FormTextField extends React.Component {
  render() {
    return <Field {...this.props} component={InnerFormTextField} />

    // const propsToRender = omit(this.props, ['ref'])
    // const ref = this.props.ref || this.props.name
    // return <Field {...propsToRender} ref={ref} component={InnerFormTextField} />
  }
}
