import gql from "graphql-tag"

export default gql`
  query restaurantsPageQuery(
    $limit: Int
    $offset: Int
    $nameSearch: String
    $marketId: Int
  ) {
    restaurants(
      page: { limit: $limit, offset: $offset }
      filter: { nameSearch: $nameSearch, marketId: $marketId }
      sort: [
        { direction: desc, field: active }
        { direction: asc, field: name }
      ]
    ) {
      total
      records {
        id
        phone
        active
        email
        logo {
          original
          small
          medium
          thumb
        }
        name
        trucks {
          id
          name
          image {
            original
            small
            high
            horizontalStretch
            medium
            thumb
          }
        }
        menus {
          total
          records {
            id
            foodTypes {
              id
              name
            }
          }
        }
        items {
          total
        }
        locations {
          total
        }
        userDevices {
          id
          appVersion
        }
        installedApp
        createdAt
        stripeLink
        stripeCustomerLink
        completion {
          name
          required
        }
      }
    }
  }
`
