import React from "react"
import Responsive from "react-responsive"

export const BREAKPOINTS = {
  mobile: 0,
  desktop: 768
}

export const OnMobile = (props) => (
  <Responsive {...props} maxWidth={BREAKPOINTS.desktop - 1} />
)
export const OnDesktop = (props) => (
  <Responsive {...props} minWidth={BREAKPOINTS.desktop} />
)
