/*
 *
 * BuildersPage actions
 *
 */

import { LOAD_PAGE, PAGE_LOADED } from "./constants"

export function loadPage() {
  return {
    type: LOAD_PAGE
  }
}

export function pageLoaded() {
  return {
    type: PAGE_LOADED
  }
}
