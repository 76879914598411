/* eslint-disable */
// copy and paste + overrides from:
// https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/TablePaginationActions/TablePaginationActions.js
import React from "react"
import PropTypes from "prop-types"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import { withTheme } from "@material-ui/core/styles"
import _ from "lodash"

/**
 * @ignore - internal component.
 */
class TablePaginationActions extends React.PureComponent {
  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1)
  }

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1)
  }

  render() {
    const {
      backIconButtonProps,
      count,
      nextIconButtonProps,
      onChangePage,
      page,
      rowsPerPage,
      theme,
      ...other
    } = this.props

    const disablePrev = page === 0
    const disableNext = page >= Math.ceil(count / rowsPerPage) - 1
    const prevButton = (
      <IconButton
        onClick={this.handleBackButtonClick}
        disabled={disablePrev}
        {..._.omit(backIconButtonProps, ["title"])}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
    )
    const nextButton = (
      <IconButton
        onClick={this.handleNextButtonClick}
        disabled={disableNext}
        {..._.omit(nextIconButtonProps, ["title"])}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    )
    return (
      <div {...other}>
        {disablePrev ? (
          prevButton
        ) : (
          <Tooltip
            placement="top"
            title="Use 'Left' arrow key on your keyboard"
          >
            {prevButton}
          </Tooltip>
        )}
        {disableNext ? (
          nextButton
        ) : (
          <Tooltip
            placement="top"
            title="Use 'Right' arrow key on your keyboard"
          >
            {nextButton}
          </Tooltip>
        )}
      </div>
    )
  }
}

TablePaginationActions.propTypes = {
  /**
   * Properties applied to the back arrow `IconButton` element.
   */
  backIconButtonProps: PropTypes.object,
  /**
   * The total number of rows.
   */
  count: PropTypes.number.isRequired,
  /**
   * Properties applied to the next arrow `IconButton` element.
   */
  nextIconButtonProps: PropTypes.object,
  /**
   * Callback fired when the page is changed.
   *
   * @param {object} event The event source of the callback
   * @param {number} page The page selected
   */
  onChangePage: PropTypes.func.isRequired,
  /**
   * The zero-based index of the current page.
   */
  page: PropTypes.number.isRequired,
  /**
   * The number of rows per page.
   */
  rowsPerPage: PropTypes.number.isRequired,
  /**
   * @ignore
   */
  theme: PropTypes.object.isRequired
}

export default withTheme(TablePaginationActions)
