import FormMoneyField from "common/components/FormMoneyField"
import React from "react"
import PropTypes from "prop-types"
import { reduxForm } from "redux-form/immutable"
import Button from "@material-ui/core/Button"
import FormTextField from "../../../common/components/FormTextField/index"
import FormCheckbox from "../../../common/components/FormCheckbox/index"
import { formSubmit } from "./actions"
import { isValid, RULES } from "../../../common/utils/validation/index"
import scrollToFirstError from "common/utils/form/scrollToFirstError"

class MenuTypeForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    error: PropTypes.string,
    submitting: PropTypes.bool,
    initialValues: PropTypes.object
  }

  render() {
    const { handleSubmit, submitting } = this.props
    const submit = handleSubmit(formSubmit)
    const persisted =
      this.props.initialValues && this.props.initialValues.get("id")
    return (
      <form onSubmit={submit}>
        <FormTextField name="name" type="text" label="Name" fullWidth />
        <FormTextField
          name="cateringName"
          type="text"
          label="Catering Name"
          fullWidth
        />
        <FormMoneyField
          name="minCateringBudgetCents"
          type="text"
          label="Min catering budget per item"
          fullWidth
        />
        <FormTextField name="position" type="text" label="Position" fullWidth />
        <FormCheckbox name="singlePerCart" label="Single per cart?" />
        <div>
          <Button
            type="submit"
            variant="contained"
            disabled={submitting}
            style={{ width: "100%" }}
            color="secondary"
          >
            {persisted ? "Update" : "Create"}
          </Button>
        </div>
      </form>
    )
  }
}

const validate = (values) => {
  const form = {
    name: values.get("name"),
    cateringName: values.get("cateringName"),
    position: values.get("position")
  }
  const { messages: errors } = isValid(form, {
    position: RULES.required
  })
  return errors
}

export default reduxForm({
  form: "menuTypeForm",
  enableReinitialize: true,
  onSubmitFail: scrollToFirstError,
  validate
})(MenuTypeForm)
