import { call, takeLatest, put } from "redux-saga/effects"
import { LOAD_PAGE, REMOVE_MENU_TYPE } from "./constants"
import { pageLoaded } from "./actions"
import api from "admins/api"
import { errorNotification } from "common/components/NotificationStack/actions"
import requireUser from "common/helpers/requireUser"
import {
  startBlockUI,
  stopBlockUI
} from "../../../common/components/BlockUIConnected/actions"

function* loadPageSaga() {
  yield requireUser()
  const response = yield call(api.menuTypes.index)
  if (response.err) {
    return yield put(
      errorNotification(response, "Error Loading Menu types Information")
    )
  }
  const { menuTypes } = response.data
  yield put(pageLoaded({ menuTypes }))
}

function* removeMenuTypeSaga({ payload: { id } }) {
  yield put(startBlockUI())
  const response = yield call(api.menuTypes.destroy, { id })
  yield put(stopBlockUI())
  if (response.err) {
    return yield put(
      errorNotification(response, "Error During Menu Item Destroying")
    )
  }
  const { menuTypes } = response.data
  yield put(pageLoaded({ menuTypes }))
}

export default function* defaultSaga() {
  yield takeLatest(LOAD_PAGE, loadPageSaga)
  yield takeLatest(REMOVE_MENU_TYPE, removeMenuTypeSaga)
}
