import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { omit } from "lodash"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import withMobileDialog from "@material-ui/core/withMobileDialog"
import Button from "@material-ui/core/Button"

class ConfirmationDialog extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func
  }

  static defaultProps = {
    onConfirm: () => {},
    onClose: () => {}
  }

  render() {
    const { title, description, onClose, onConfirm } = this.props
    const propsToRender = omit(this.props, [
      "title",
      "description",
      "onClose",
      "onConfirm"
    ])
    return (
      <Dialog aria-labelledby="responsive-dialog-title" {...propsToRender}>
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {description && <DialogContentText>{description}</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onConfirm} color="primary" autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withMobileDialog()(ConfirmationDialog)
