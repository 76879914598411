/*
 *
 * MenuTypesPage reducer
 *
 */

import { fromJS } from "immutable"
import { LOAD_PAGE, PAGE_LOADED } from "./constants"

export const initialState = fromJS({
  isLoading: true,
  menuTypes: []
})

function menuTypesPageReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_PAGE:
      return state.set("isLoading", true)
    case PAGE_LOADED:
      return state
        .set("isLoading", false)
        .set("menuTypes", action.payload.menuTypes)
    default:
      return state
  }
}

export default menuTypesPageReducer
