import { takeLatest, put } from "redux-saga/effects"
import { LOAD_PAGE } from "./constants"
import { pageLoaded } from "./actions"
import requireUser from "common/helpers/requireUser"

function* loadPageSaga() {
  yield requireUser()
  yield put(pageLoaded())
}

export default function* defaultSaga() {
  yield takeLatest(LOAD_PAGE, loadPageSaga)
}
