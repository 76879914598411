import { createSelector } from "reselect"
import { initialState } from "./reducer"

/**
 * Direct selector to the menuTypesPage state domain
 */
const selectMenuTypesPageDomain = (state) =>
  state.get("menuTypesPage") || initialState

/**
 * Other specific selectors
 */

/**
 * Default selector used by MenuTypesPage
 */

const makeSelectMenuTypesPage = () =>
  createSelector(selectMenuTypesPageDomain, (substate) => substate.toJS())

export default makeSelectMenuTypesPage
export { selectMenuTypesPageDomain }
