import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import React from "react"
import { Query } from "@apollo/client/react/components"
import marketsQuery from "./query"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import PageError from "common/components/PageError"
import PageLoader from "common/components/PageLoader"
import PropTypes from "prop-types"

class MarketFilter extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }

  static defaultProps = {
    value: ""
  }

  handleChange = (event) => {
    this.props.onChange(event.target.value)
  }

  renderContent = () => (
    <Query fetchPolicy="cache-and-network" query={marketsQuery}>
      {({ data, error }) => {
        if (error) return <PageError message={error.message} />
        if (!data || !data.markets) return <PageLoader />

        return this.renderMarketFilter(data)
      }}
    </Query>
  )

  renderMarketFilter = ({ markets: { records } }) => {
    const { label } = this.props
    const field = () => (
      <Select
        fullWidth={this.props.fullWidth}
        value={this.props.value || ""}
        onChange={this.handleChange}
        inputProps={{
          name: "marketId"
        }}
        displayEmpty
      >
        <MenuItem value="">All markets</MenuItem>
        {records.map((record) => (
          <MenuItem value={record.id} key={record.id}>
            {record.name}
          </MenuItem>
        ))}
      </Select>
    )
    return label ? (
      <FormControl margin={this.props.margin}>
        <InputLabel shrink={true}>{label}</InputLabel>
        {field()}
      </FormControl>
    ) : (
      field()
    )
  }

  render() {
    return this.renderContent()
  }
}

export default MarketFilter
