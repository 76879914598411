/**
 * @param errors
 */

export default function scrollToFirstError(errors) {
  const key = Object.keys(errors)[0]

  if (key) {
    const element = document.getElementsByName([key])[0]
    if (element) {
      const node = element.type === "hidden" ? element.parentElement : element
      node.scrollIntoView({ behavior: "smooth" })
    }
  }
}
