import { FORM_ERROR } from "final-form"
import { FetchResult } from "@apollo/client"

import getMutationResponseErrors from "./getMutationResponseErrors"

export default function getMutationResponseError<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  MutationData extends Record<string, any>
>(response: FetchResult<MutationData>): string | null {
  // @ts-ignore
  const errorsHash = getMutationResponseErrors(response)
  return errorsHash ? (errorsHash[FORM_ERROR] as string) || null : null
}
