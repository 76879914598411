/*
 *
 * MenuTypesPage actions
 *
 */

import { LOAD_PAGE, PAGE_LOADED, REMOVE_MENU_TYPE } from "./constants"

export function loadPage() {
  return {
    type: LOAD_PAGE
  }
}

export function pageLoaded({ menuTypes }) {
  return {
    type: PAGE_LOADED,
    payload: { menuTypes }
  }
}

export function removeMenuType({ id }) {
  return {
    type: REMOVE_MENU_TYPE,
    payload: { id }
  }
}
