const rules = {
  email: {
    email: true,
    presence: true
  },
  name: {
    presence: true
  },
  firstName: {
    presence: true
  },
  lastName: {
    presence: true
  },
  password: {
    presence: true,
    length: {
      minimum: 6
    }
  },
  required: {
    presence: {
      allowEmpty: false
    }
  },
  phone: {
    presence: true,
    length: {
      is: 10
    }
  },
  cvc: {
    presence: true,
    length: {
      minimum: 3,
      maximum: 4
    }
  },
  stars: {
    presence: true
  },
  comment: {
    presence: true
  },
  zip: {
    presence: true,
    length: {
      is: 5
    }
  },
  passwordConfirmation: {
    equality: {
      attribute: "password",
      message: "^Passwords do not match"
    }
  },
  equalsTo: (field) => ({ equality: field })
}

export default rules
