/* tslint:disable */
/* eslint-disable */
// @ts-ignore
import * as Types from "types/global-graphql-types"

import { DocumentNode } from "graphql"
import * as Apollo from "@apollo/client"
import * as ApolloReactHooks from "common/utils/extendApolloHooks"
const defaultOptions = {} as const
export type LotsOptionsQueryVariables = Types.Exact<{ [key: string]: never }>

export type LotsOptionsQuery = {
  __typename: "Query"
  lots: {
    __typename: "LotCollection"
    total: number
    records: Array<{ __typename: "Lot"; id: number; name: string }>
  }
}

export const LotsOptionsQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LotsOptionsQuery" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lots" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "page" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "4000" }
                    }
                  ]
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "custom" },
                      value: { kind: "BooleanValue", value: false }
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "active" },
                      value: { kind: "BooleanValue", value: true }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "records" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown) as DocumentNode

/**
 * __useLotsOptionsQuery__
 *
 * To run a query within a React component, call `useLotsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLotsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLotsOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLotsOptionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LotsOptionsQuery,
    LotsOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<LotsOptionsQuery, LotsOptionsQueryVariables>(
    LotsOptionsQueryDocument,
    options
  )
}
export function useLotsOptionsQueryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LotsOptionsQuery,
    LotsOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    LotsOptionsQuery,
    LotsOptionsQueryVariables
  >(LotsOptionsQueryDocument, options)
}
export type LotsOptionsQueryHookResult = ReturnType<typeof useLotsOptionsQuery>
export type LotsOptionsQueryLazyQueryHookResult = ReturnType<
  typeof useLotsOptionsQueryLazyQuery
>
export type LotsOptionsQueryQueryResult = Apollo.QueryResult<
  LotsOptionsQuery,
  LotsOptionsQueryVariables
>
