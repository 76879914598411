import React from "react"
import PropTypes from "prop-types"
import TextField from "@material-ui/core/TextField"
import { Field } from "react-final-form"
import Autocomplete from "@material-ui/lab/Autocomplete"

class InnerField extends React.Component {
  static propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    description: PropTypes.string,
    placeholder: PropTypes.string,
    meta: PropTypes.object,
    InputProps: PropTypes.object,
    children: PropTypes.any
  }

  static defaultProps = {
    meta: {},
    InputProps: {}
  }

  onChange = (event, newValue) => {
    this.props.input.onChange(newValue.map((v) => (v.value ? v.value : v)))
  }

  render() {
    const {
      input,
      label,
      description,
      placeholder,
      multipleOption,
      options,
      InputProps,
      variant,
      meta: { touched, error: syncError, submitError, submitting },
      ...rest
    } = this.props
    const error = syncError || submitError
    const showError = (touched || submitError) && !!error
    const actualDescription = description ? <em>{description}</em> : description
    const value = (input.value || [])
      .map((selectedValue) =>
        options.find((option) => option.value === selectedValue)
      )
      .filter((o) => o) // remove not found values
    return (
      <Autocomplete
        value={value}
        onChange={this.onChange}
        multiple={multipleOption}
        options={options}
        getOptionLabel={(option) => option.label}
        defaultValue={[]}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            disabled={submitting}
            variant={variant}
            placeholder={placeholder}
            margin="normal"
            helperText={showError ? error : actualDescription}
            {...rest}
          />
        )}
      />
    )
  }
}

export default class UiMultipleAutocompleteSelectField extends React.Component {
  render() {
    return (
      <Field
        {...this.props}
        component={InnerField}
        multipleOption={this.props.multiple}
      />
    )
  }
}
