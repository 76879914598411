/*
 *
 * BuildersPage reducer
 *
 */

import { fromJS } from "immutable"
import { LOAD_PAGE, PAGE_LOADED } from "./constants"

export const initialState = fromJS({
  isLoading: true
})

function buildersPageReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_PAGE:
      return state.set("isLoading", true)
    case PAGE_LOADED:
      return state.set("isLoading", false)
    default:
      return state
  }
}

export default buildersPageReducer
