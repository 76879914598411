import {
  startBlockUI,
  stopBlockUI
} from "common/components/BlockUIConnected/actions"
import {
  errorNotification,
  successNotification
} from "common/components/NotificationStack/actions"
import Rollbar from "common/utils/Rollbar"
import { OperationVariables } from "@apollo/client/core/types"
import { FetchResult } from "@apollo/client"
import { Dispatch } from "redux"
import mutationError from "common/utils/getMutationError"

type Args<T, TVariables = OperationVariables> = {
  values?: TVariables
  mutation: (values?: TVariables) => Promise<FetchResult<T>>
  dispatch: Dispatch
  successMessage?: string | null
  swallowExceptions?: boolean
  confirmation?: Promise<void> | null
}

export default async function executeMutation<
  T,
  TVariables = OperationVariables
>({
  dispatch,
  mutation,
  values = {} as TVariables,
  successMessage = null,
  swallowExceptions = false,
  confirmation = null
}: Args<T, TVariables>) {
  if (confirmation) {
    try {
      await confirmation
    } catch (e) {
      return null
    }
  }

  let error = null
  try {
    dispatch(startBlockUI())
    const response = await mutation(values)
    const { data } = response
    const errorMessage = mutationError(response)
    if (errorMessage) {
      Rollbar.notify("Mutation error", error)
      dispatch(errorNotification(errorMessage))
      error = new Error(errorMessage)
    } else {
      if (successMessage) {
        dispatch(successNotification(successMessage))
      }
      return data!
    }
  } catch (e) {
    dispatch(errorNotification(e))
    error = e
  } finally {
    dispatch(stopBlockUI())
  }
  if (swallowExceptions) {
    return null
  }
  throw error
}
