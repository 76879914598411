import { select, take } from "redux-saga/effects"
import { USER_LOADED } from "common/constants"
import { makeSelectUser } from "common/selectors"

export default function* requireUser() {
  let user = yield select(makeSelectUser())
  if (!user) {
    yield take(USER_LOADED, () => {})
    user = yield select(makeSelectUser())
  }
  return user
}
