import React from "react"
import { compose } from "redux"
import Autosuggest from "react-autosuggest"
import match from "autosuggest-highlight/match"
import { push } from "connected-react-router/immutable"
import parse from "autosuggest-highlight/parse"
import Paper from "@material-ui/core/Paper"
import MenuItem from "@material-ui/core/MenuItem"
import { withStyles } from "@material-ui/core/styles"
import { withApollo } from "@apollo/client/react/hoc"
import { connect } from "react-redux"
import styled from "styled-components"
import { debounce } from "lodash"
import GoogleStyledInput from "common/components/GoogleStyledInput"
import AccountCircle from "@material-ui/icons/AccountCircle"
import TruckIcon from "@material-ui/icons/LocalShipping"
import RestaurantIcon from "@material-ui/icons/Store"
import LotIcon from "@material-ui/icons/PictureInPicture"
import OrderIcon from "@material-ui/icons/Receipt"
import FoodIcon from "@material-ui/icons/RestaurantMenu"
import cn from "classnames"

const styles = (theme) => ({
  container: {
    position: "relative",
    display: "inline-block",
    marginRight: "10px",
    [theme.breakpoints.up("md")]: {
      marginRight: "30px"
    },
    flexGrow: 1
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    width: "600px",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      width: "100%"
    }
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  icon: {
    margin: theme.spacing(1),
    color: "#464646"
  }
})

const SearchContainer = styled.div`
  display: flex;
  height: 36px;
  margin-left: 20px;
  flex-grow: 1;
  @media (max-width: 600px) {
    margin-left: auto;
    flex-grow: inherit;
    &:focus-within {
      margin-left: 20px;
      flex-grow: 1;
    }
  }
`

const SuggestionContainer = styled.div`
  display: flex;
  width: 600px;
  justify-content: flex-start;
  align-items: center;
`

const SuggestionId = styled.div`
  margin-left: 0px;
  margin-right: 10px;
`

const SuggestionDescription = styled.div`
  font-size: 0.8em;
  max-width: 380px;
  overflow-x: hidden;
`

class QuickSearch extends React.PureComponent {
  state = {
    value: "",
    loading: false,
    effectiveValue: "",
    suggestions: []
  }

  search = ({ value }) => {
    const variables = {
      search: value
    }
    this.setState(
      {
        loading: true
      },
      () => {
        this.props.client
          .query({ query: this.props.query, variables })
          .then((result) => {
            this.setState({
              loading: false,
              effectiveValue: value,
              suggestions: result.data.quickSearch || []
            })
          })
          .catch((err) => {
            // eslint-disable-next-line
            console.error(err)
          })
      }
    )
  }

  debouncedSearch = debounce(this.search, 300)

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  handleChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    })
  }

  suggestionSelected = (_, { suggestion }) => {
    this.props.dispatch(push(suggestion.path))
    this.setState({
      value: ""
    })
  }

  renderInput = (inputProps) => {
    const { ref, ...other } = inputProps

    return (
      <GoogleStyledInput
        inputProps={{
          ref,
          ...other
        }}
        loading={this.state.loading}
      />
    )
  }

  renderIcon = (type) => {
    const { classes } = this.props
    if (type === "Truck") {
      return <TruckIcon className={classes.icon} />
    }
    if (type === "Catering") {
      return <FoodIcon className={classes.icon} />
    }
    if (type === "Restaurant") {
      return <RestaurantIcon className={classes.icon} />
    }
    if (type === "Customer") {
      return <AccountCircle className={classes.icon} />
    }
    if (type === "Lot") {
      return <LotIcon className={classes.icon} />
    }
    if (type === "Order") {
      return <OrderIcon className={classes.icon} />
    }
    return null
  }

  renderSuggestion = (state) => (suggestion, { isHighlighted }) => {
    const { type, description, name, active } = suggestion
    const { effectiveValue: searchQuery } = state
    const idMatches = match(name, searchQuery)
    const idParts = parse(name, idMatches)

    const descriptionsMatches = match(description, searchQuery)
    const descriptionParts = parse(description, descriptionsMatches)

    return (
      <MenuItem selected={isHighlighted} component="div">
        <SuggestionContainer className={cn({ "bg-red-200": !active })}>
          <SuggestionId>
            <div>
              {this.renderIcon(type)}
              {idParts.map((part, index) =>
                part.highlight ? (
                  <span key={String(index)} style={{ fontWeight: 500 }}>
                    {part.text}
                  </span>
                ) : (
                  <strong key={String(index)} style={{ fontWeight: 300 }}>
                    {part.text}
                  </strong>
                )
              )}
            </div>
          </SuggestionId>
          <SuggestionDescription>
            {descriptionParts.map((part, index) =>
              part.highlight ? (
                <span key={String(index)} style={{ fontWeight: 500 }}>
                  {part.text}
                </span>
              ) : (
                <strong key={String(index)} style={{ fontWeight: 300 }}>
                  {part.text}
                </strong>
              )
            )}
          </SuggestionDescription>
        </SuggestionContainer>
      </MenuItem>
    )
  }

  renderSuggestionsContainer = (options) => {
    const { containerProps, children } = options

    return (
      <Paper {...containerProps} square>
        {children}
      </Paper>
    )
  }

  getSuggestionValue = (suggestion) => `${suggestion.id}-${suggestion.type}`

  render() {
    const { classes } = this.props

    return (
      <SearchContainer>
        <Autosuggest
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          renderInputComponent={this.renderInput}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.debouncedSearch}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion(this.state)}
          onSuggestionSelected={this.suggestionSelected}
          inputProps={{
            classes,
            placeholder: "Search...",
            value: this.state.value,
            onChange: this.handleChange,
            onFocus: this.props.onFocus,
            onBlur: this.props.onBlur
          }}
        />
      </SearchContainer>
    )
  }
}

export default compose(connect(), withApollo, withStyles(styles))(QuickSearch)
