import React from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form/immutable"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import Checkbox from "@material-ui/core/Checkbox"
import Switch from "@material-ui/core/Switch"

class InnerFormCheckbox extends React.Component {
  static propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    description: PropTypes.any,
    margin: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(["Switch", "Checkbox"]),
    meta: PropTypes.object,
    options: PropTypes.array,
    children: PropTypes.any,
    fullWidth: PropTypes.bool
  }

  static defaultProps = {
    meta: {},
    fullWidth: false,
    margin: "normal",
    type: "Switch"
  }

  render() {
    const {
      input,
      label,
      fullWidth,
      margin,
      type,
      description,
      meta: { touched, error }
    } = this.props
    let Component
    if (type === "Switch") {
      Component = Switch
    } else {
      Component = Checkbox
    }
    const showError = touched && !!error
    return (
      <FormControl error={showError} fullWidth={fullWidth} margin={margin}>
        <FormControlLabel
          onClick={input.onBlur}
          control={
            <Component
              checked={!!input.value}
              onChange={input.onChange}
              value="true"
              name={input.name}
            />
          }
          label={<span>{label}</span>}
        />
        <FormHelperText style={{ display: "inline-block", marginTop: 0 }}>
          {showError ? (
            <strong>
              <em>{error}</em>
            </strong>
          ) : (
            description && <span>{description}</span>
          )}
        </FormHelperText>
      </FormControl>
    )
  }
}

export default class FormCheckbox extends React.Component {
  render() {
    return <Field {...this.props} component={InnerFormCheckbox} />
  }
}
