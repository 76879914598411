import styled from "styled-components"

export const ActionsContainer = styled.div`
  padding: 0 0 20px 0;
  display: flex;
  justify-content: flex-start;
`

export const Container = styled.section`
  padding: 30px;
`

export const RestaurantCompletion = styled.section``

export const RestaurantCompletionInfo = styled.div`
  font-size: 15px;
  font-weight: bold;
`

export const RestaurantCompletionErrorInfo = styled.div`
  font-size: 13px;
  font-weight: normal;
  color: red;
`

export const SearchContainer = styled.div`
  margin-left: 50px;
`
