import gql from "graphql-tag"

export default gql`
  query marketFilterQuery {
    markets {
      records {
        id
        name
      }
    }
  }
`
