import * as Apollo from "@apollo/client"
import { ApolloError, FetchResult, MutationResult } from "@apollo/client"
import {
  useConfirmDialog as useConfirm,
  ConfirmDialogContextValues
} from "common/components/ReactConfirmation/ReactConfirmationProvider"
import uniq from "lodash/uniq"
import {
  startBlockUI,
  stopBlockUI
} from "common/components/BlockUIConnected/actions"
import getMutationError from "./getMutationResponseError"
import { useDispatch } from "react-redux"

import {
  errorNotification,
  successNotification
} from "common/components/NotificationStack/actions"
import { useEffect, useRef } from "react"
import useDeepCompareCallback from "common/hooks/useDeepCompareCallback"
import flatten from "lodash/flatten"
import isArray from "lodash/isArray"

type ConfirmArgs = Parameters<ConfirmDialogContextValues>

export * from "@apollo/client"

interface Notifications {
  onSuccess?:
    | {
        message: string
        dismissAfter?: number
      }
    | string
  onError?:
    | {
        message?: string
      }
    | string
    | boolean
}

export type QueryHookOptions<TData, TVariables> = Apollo.QueryHookOptions<
  TData,
  TVariables
> & {
  notifications?: Notifications
}

export function useQuery<
  TData = unknown,
  TVariables = Apollo.OperationVariables
>(
  query: Apollo.DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
): Apollo.QueryResult<TData, TVariables> {
  const dispatch = useDispatch()

  return Apollo.useQuery<TData, TVariables>(query, {
    ...options,
    onCompleted: (data) => {
      if (options?.notifications?.onSuccess) {
        dispatch(
          successNotification(
            ...successMessageNotification(options?.notifications?.onSuccess)
          )
        )
      }
      if (options?.onCompleted) options.onCompleted(data)
    },
    onError: (e) => {
      if (options?.notifications?.onError) {
        dispatch(
          errorNotification(
            errorMessageNotification(
              options?.notifications?.onError,
              getSystemError(e)
            )
          )
        )
      }
      if (options?.onError) options.onError(e)
    }
  })
}

export type MutationHookOptions<TData, TVariables> = Apollo.MutationHookOptions<
  TData,
  TVariables
> & {
  notifications?: Notifications
  confirm?: ConfirmArgs | string
  blockUi?: boolean
}

type MutationFunctionOptions<
  TData,
  TVariables
> = Apollo.MutationFunctionOptions<TData, TVariables> & {
  notifications?: Notifications
  confirm?: ConfirmArgs | string
  blockUi?: boolean
}

export function useMutation<
  TData = unknown,
  TVariables = Apollo.OperationVariables
>(
  mutation: Apollo.DocumentNode,
  options: MutationHookOptions<TData, TVariables> = {}
): [
  (
    mutationOptions?: MutationFunctionOptions<TData, TVariables>
  ) => Promise<FetchResult<TData> | null>,
  MutationResult<TData>
] {
  const dispatch = useDispatch()

  const confirmation = useConfirm()
  const confirmationRef = useRef(confirmation)
  useEffect(() => {
    confirmationRef.current = confirmation
  }, [confirmation])

  const [onMutate, mutationResult] = Apollo.useMutation<TData, TVariables>(
    mutation,
    options
  )

  const onMutateWrapper = useDeepCompareCallback(
    async (
      mutationOptions: MutationFunctionOptions<TData, TVariables> = {}
    ) => {
      const actualConfirm = mutationOptions.confirm ?? options.confirm
      if (actualConfirm) {
        try {
          await confirmationRef.current(...makeConfirmOptions(actualConfirm))
        } catch (e) {
          return null
        }
      }
      const actualBlockUi = mutationOptions.blockUi ?? options.blockUi
      try {
        if (actualBlockUi) {
          dispatch(startBlockUI())
        }
        const response = await onMutate({
          ...mutationOptions,
          onCompleted: () => {},
          onError: () => {}
        })
        const error = getMutationError(response)
        if (error) {
          const onError = mutationOptions?.onError ?? options?.onError
          if (onError) {
            onError(new ApolloError({ errorMessage: error }))
          }
          const onErrorNotification =
            mutationOptions?.notifications?.onError ??
            options?.notifications?.onError
          if (onErrorNotification) {
            dispatch(
              errorNotification(
                errorMessageNotification(onErrorNotification, error)
              )
            )
            return null
          }
          return response
        }
        const onSuccessNotification =
          mutationOptions?.notifications?.onSuccess ??
          options?.notifications?.onSuccess
        if (onSuccessNotification) {
          dispatch(
            successNotification(
              ...successMessageNotification(onSuccessNotification)
            )
          )
        }
        const onCompleted = mutationOptions?.onCompleted ?? options?.onCompleted
        if (onCompleted) onCompleted(response.data!)

        return response
      } finally {
        if (actualBlockUi) {
          dispatch(stopBlockUI())
        }
      }
    },
    [confirmationRef, onMutate, options]
  )

  return [onMutateWrapper, mutationResult]
}

type SuccessOptions = {
  dismissAfter?: number
}

function successMessageNotification(
  onSuccess: Notifications["onSuccess"]
): [string, SuccessOptions] {
  let message: string
  let options = {}
  if (typeof onSuccess === "string") {
    message = onSuccess
  } else {
    const { message: newMessage, ...newOptions } = onSuccess as {
      message: string
      dismissAfter?: number
    }
    message = newMessage
    options = newOptions
  }
  return [message, options]
}

function errorMessageNotification(
  onError: Notifications["onError"],
  errorMessage: string
): string {
  let message: string
  if (typeof onError === "string") {
    message = onError
  } else if (typeof onError === "boolean") {
    message = errorMessage
  } else {
    message = onError?.message || errorMessage
  }
  return message
}

function makeConfirmOptions(input: ConfirmArgs | string): ConfirmArgs {
  if (typeof input === "string") {
    return ["Are you sure?", input]
  }
  return input
}

function getSystemErrorMessages(err: ApolloError): string[] {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const networkErrors = (
    (err?.networkError as any)?.result?.errors ?? ([] as { message: string }[])
  ).map((e: Error) => e.message)
  /* eslint-enable @typescript-eslint/no-explicit-any */
  const networkError = networkErrors.length ? null : err?.networkError?.message
  const gqlErrors = err?.graphQLErrors?.map((i) => i.message) || []
  return [...networkErrors, err.message, networkError, ...gqlErrors].filter(
    (e) => e && e.length > 0
  )
}

export function getSystemError(err: ApolloError | ApolloError[]): string {
  const errors: ApolloError[] = isArray(err)
    ? (err as ApolloError[])
    : [err as ApolloError]
  const messages = uniq(flatten(errors.map(getSystemErrorMessages)))
  return messages.join(", \n").trim()
}
