import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router/immutable"
import queryString from "query-string"
import hoistNonReactStatics from "hoist-non-react-statics"
import PropTypes from "prop-types"

const withSearchParam = (WrappedComponent) => {
  const klass = class extends React.PureComponent {
    static propTypes = {
      dispatch: PropTypes.func.isRequired
    }

    query = () => queryString.parse(this.props.location.search)

    onChangeSearch = ({ search }) => {
      const newParams = { ...this.query(), search }
      if (!search) delete newParams.search
      const newQuery = queryString.stringify(newParams)
      this.props.dispatch(push(`${this.props.location.pathname}?${newQuery}`))
    }

    render() {
      const query = this.query()
      return (
        <WrappedComponent
          search={query.search ? query.search : null}
          onChangeSearch={this.onChangeSearch}
          {...this.props}
        />
      )
    }
  }
  klass.displayName = `withSearchParam(${
    WrappedComponent.displayName || WrappedComponent.name
  })`
  hoistNonReactStatics(klass, WrappedComponent)
  return connect()(klass)
}

export default withSearchParam
