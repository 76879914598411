/**
 *
 * MenuTypesPage
 *
 */

import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { push } from "connected-react-router/immutable"
import { compose } from "redux"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import injectSaga from "admins/utils/injectSaga"
import injectReducer from "admins/utils/injectReducer"
import makeSelectMenuTypesPage from "./selectors"
import { loadPage, removeMenuType } from "./actions"
import reducer from "./reducer"
import saga from "./saga"
import {
  ActionsContainer,
  Container,
  ListButtonContainer,
  ActionButton
} from "./parts"
import {
  OnDesktop,
  OnMobile
} from "../../../common/components/Breakpoints/index"

import ConfirmationDialog from "../../../common/components/ConfirmationDialog/index"
import BuildersPage from "admins/containers/BuildersPage"
import Page from "admins/components/Page"

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    width: "100%"
  },
  button: {},
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  listTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    paddingBottom: "10px"
  }
})

class MenuTypesPage extends React.Component {
  static breadcrumbs = () =>
    BuildersPage.breadcrumbs().concat([
      {
        name: "Menu Types",
        url: "/builders/menu-types"
      }
    ])
  constructor(props, context) {
    super(props, context)
    this.state = {
      confirmationVisible: false,
      activeItemId: null
    }
  }

  // eslint-disable-line react/prefer-stateless-function
  componentDidMount() {
    this.props.dispatch(loadPage())
  }

  toggleConfirmationModal(visible) {
    this.setState({ confirmationVisible: visible })
  }

  goToMenuType = (id = null) => () => {
    const url = id ? `/${id}` : "/new"
    return this.props.dispatch(push(`/builders/menu-types${url}`))
  }

  onRemoveMenuType(id) {
    return () => {
      this.toggleConfirmationModal(true)
      this.setState({ activeItemId: id })
    }
  }

  removeMenuItem = () => {
    const { activeItemId: id } = this.state
    this.props.dispatch(removeMenuType({ id }))
    this.toggleConfirmationModal(false)
  }

  renderDesktopList() {
    const {
      page: { menuTypes },
      classes
    } = this.props
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Position</TableCell>
            <TableCell>Min Catering Price</TableCell>
            <TableCell>Single per cart</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {menuTypes.map((menuType, index) => (
            <TableRow key={menuType.id}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>
                {menuType.name}
                <br />
                Catering: {menuType.cateringName}
              </TableCell>
              <TableCell>{menuType.position}</TableCell>
              <TableCell>{menuType.minCateringBudget.formatted}</TableCell>
              <TableCell>{menuType.singlePerCart ? "yes" : "no"}</TableCell>
              <TableCell>
                <ActionButton>
                  <Button
                    variant="contained"
                    onClick={this.goToMenuType(menuType.id)}
                  >
                    Edit
                  </Button>
                </ActionButton>
                {/*<ActionButton>*/}
                {/*  <Button*/}
                {/*    variant="contained"*/}
                {/*    color="secondary"*/}
                {/*    onClick={this.onRemoveMenuType(menuType.id)}*/}
                {/*  >*/}
                {/*    Remove*/}
                {/*  </Button>*/}
                {/*</ActionButton>*/}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }

  renderMobileList() {
    const {
      page: { menuTypes },
      classes
    } = this.props
    return (
      <div>
        {menuTypes.map((menuType, index) => (
          <Accordion key={menuType.id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>
                {index + 1}. {menuType.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Typography className={classes.listTitle}>
                  Name: {menuType.name}
                  <br />
                  Catering: {menuType.cateringName}
                </Typography>
                <Typography className={classes.listTitle}>
                  Position: {menuType.position}
                </Typography>
                <Typography className={classes.listTitle}>
                  Min Catering Price: {menuType.minCateringBudget.formatted}
                </Typography>
                <Typography className={classes.listTitle}>
                  Single per cart: {menuType.singlePerCart ? "yes" : "no"}
                </Typography>
                <ListButtonContainer>
                  <ActionButton>
                    <Button
                      variant="contained"
                      onClick={this.goToMenuType(menuType.id)}
                    >
                      Edit
                    </Button>
                  </ActionButton>
                  {/*<ActionButton>*/}
                  {/*  <Button*/}
                  {/*    variant="contained"*/}
                  {/*    color="secondary"*/}
                  {/*    onClick={this.onRemoveMenuType(menuType.id)}*/}
                  {/*  >*/}
                  {/*    Remove*/}
                  {/*  </Button>*/}
                  {/*</ActionButton>*/}
                </ListButtonContainer>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    )
  }

  renderItems = () => {
    const { classes } = this.props
    return (
      <Paper className={classes.root}>
        <Container>
          <ActionsContainer>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={this.goToMenuType()}
            >
              Add Menu type
            </Button>
          </ActionsContainer>
          <OnDesktop>{this.renderDesktopList()}</OnDesktop>
          <OnMobile>{this.renderMobileList()}</OnMobile>
        </Container>
      </Paper>
    )
  }

  breadcrumbs = () => MenuTypesPage.breadcrumbs()

  renderContent = () => {
    const { confirmationVisible } = this.state
    return (
      <div>
        <div className="row">
          <div className="col">{this.renderItems()}</div>
        </div>
        <ConfirmationDialog
          fullScreen={false}
          title="Are you sure?"
          description="Are you sure you wanna delete the item?"
          open={confirmationVisible}
          onClose={() => this.toggleConfirmationModal(false)}
          onConfirm={this.removeMenuItem}
        />
      </div>
    )
  }

  render() {
    return (
      <Page
        breadcrumbs={this.breadcrumbs}
        page={this.props.page}
        render={this.renderContent}
        title="Manage Menu Types"
      />
    )
  }
}

MenuTypesPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired,
  classes: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  page: makeSelectMenuTypesPage()
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({
  key: "menuTypesPage",
  reducer
})
const withSaga = injectSaga({ key: "menuTypesPage", saga })

export default compose(
  withReducer,
  withSaga,
  withConnect
)(withStyles(styles)(MenuTypesPage))
