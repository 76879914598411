import "react-app-polyfill/ie11"
import React from "react"
import ReactDOM from "react-dom"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

let App = null
if (process.env.REACT_APP_DASH === "trucks") {
  App = require("./trucks/app").default
} else if (process.env.REACT_APP_DASH === "admins") {
  App = require("./admins/app").default
} else if (process.env.REACT_APP_DASH === "corporate") {
  App = require("./corporate/app").default
} else if (process.env.REACT_APP_DASH === "organizers") {
  App = require("./organizers/app").default
} else {
  throw new Error(`Unknown dashboard: ${process.env.REACT_APP_DASH}`)
}

ReactDOM.render(
  // <React.StrictMode>
  <React.Suspense fallback="Loading...">
    <App />
  </React.Suspense>,
  // </React.StrictMode>,
  document.getElementById("root")
)

/* eslint-disable */
if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_DASH !== "admins"
) {
  ;(function (i, s, o, g, r, a, m) {
    i["GoogleAnalyticsObject"] = r
    ;(i[r] =
      i[r] ||
      function () {
        ;(i[r].q = i[r].q || []).push(arguments)
      }),
      (i[r].l = 1 * new Date())
    ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
    a.async = 1
    a.src = g
    m.parentNode.insertBefore(a, m)
  })(
    window,
    document,
    "script",
    "https://www.google-analytics.com/analytics.js",
    "ga"
  )

  ga("create", "UA-93376200-1", "auto")
  ga("send", "pageview")
}
/* eslint-enable */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// https://github.com/facebook/create-react-app/issues/5316#issuecomment-591075209
serviceWorkerRegistration.register({
  onUpdate: async (registration) => {
    // We want to run this code only if we detect a new service worker is
    // waiting to be activated.
    // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    const waitingSw = registration?.waiting
    if (waitingSw) {
      await registration.unregister()
      // Makes Workbox call skipWaiting()
      waitingSw.postMessage({ type: "SKIP_WAITING" })
      // Once the service worker is unregistered, we can reload the page to let
      // the browser download a fresh copy of our app (invalidating the cache)
      window.location.reload()
    }
  }
})
