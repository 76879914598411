import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { connect } from "react-redux"
import { compose } from "redux"
import { TextField } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import menuIdExample from "./menu-id-example.png"

const CopyMenuDialog = ({ onClose, open, onSubmit }) => {
  const [fromId, setFromId] = React.useState("")
  const [toId, setToId] = React.useState("")
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Copy Menu Items</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          This tool copies all items from one menu to another menu. In order to
          copy menu items you need to know &quot;id&quot; of both source and
          target menus. In order to find menu &quot;id&quot; you need to open
          that menu in a menu builder and look at browser address bar. It has a
          form of{" "}
          <em>
            admins.bestfoodtrucks.com/restaurants/RESTAURANT_ID/menus/MENU_ID
          </em>
          , e.g. in <em>admins.bestfoodtrucks.com/restaurants/123/menus/456</em>{" "}
          menu id is 456.
        </Typography>
        <br />
        <img
          src={menuIdExample}
          alt="Menu Id Example"
          style={{ width: "100%" }}
        />
        <br />
        <TextField
          type="text"
          margin="normal"
          value={fromId}
          onChange={(e) => setFromId(e.target.value)}
          label="From Menu Id"
          fullWidth
        />
        <TextField
          type="text"
          margin="normal"
          value={toId}
          onChange={(e) => setToId(e.target.value)}
          label="To Menu Id"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            onSubmit({ fromId, toId })
          }}
        >
          Copy Items
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default compose(connect())(CopyMenuDialog)
