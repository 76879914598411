import "whatwg-fetch"
import _ from "lodash"

function parseResponse(response) {
  return response[response.status === 204 ? "text" : "json"]()
}

function checkStatus(response) {
  if (response && response.status >= 200 && response.status < 300) {
    return response
  }

  const error = new Error(response ? response.statusText : "Offline")
  error.response = response
  throw error
}

export default function request(url, options = {}) {
  const { formData } = options
  const requestOptions = Object.assign(
    {},
    {
      mode: "cors",
      headers: Object.assign(
        {
          [window.__authTokenName]: window.__authToken || "",
          [window.__authEmailName]: window.__authEmail || ""
        },
        formData
          ? {}
          : {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
      )
    },
    _.omit(options, ["formData"])
  )
  return fetch(url, requestOptions)
    .then(checkStatus)
    .then(parseResponse)
    .then((data) => {
      let dataObject = data
      if (dataObject === "") {
        dataObject = {}
      }
      return { data: dataObject }
    })
    .catch((err) => {
      if (!err.response) {
        console.log("offline") // eslint-disable-line
        return { err, data: {}, offline: true, status: 500 }
      }
      return err.response
        .json()
        .then((data) => ({ err, data }))
        .catch(() =>
          // parse error
          ({ err, data: {} })
        )
    })
}

function jsonWithBody(url, body, options, method) {
  const { formData } = options
  const requestOptions = Object.assign(
    {},
    {
      method
    },
    options
  )
  const realBody = formData ? body : JSON.stringify(body)
  return request(
    url,
    Object.assign(
      {
        body: realBody // eslint-disable-line
      },
      requestOptions
    )
  )
}

export function postJson(url, body = {}, options = {}) {
  return jsonWithBody(url, body, options, "POST")
}

export function putJson(url, body = {}, options = {}) {
  return jsonWithBody(url, body, options, "PUT")
}

export function patchJson(url, body = {}, options = {}) {
  return jsonWithBody(url, body, options, "PATCH")
}

export function deleteJson(url, body = {}, options = {}) {
  return jsonWithBody(url, body, options, "DELETE")
}

export function toQueryString(params) {
  return Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join("&")
}
