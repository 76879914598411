import { get, post, destroy, patch, put } from "common/utils/apiRouteMethods" // eslint-disable-line

const api = {
  session: {
    create: post("/api/admins/sessions"),
    destroy: destroy("/api/admins/sessions")
  },
  admins: {
    current: get("/api/admins/users/current")
  },
  customers: {
    index: get("/api/admins/customers"),
    create: post("/api/admins/customers"),
    bulkCreate: post("/api/admins/customers/bulk_create"),
    find: get("/api/admins/customers/:id"),
    createCredit: post("/api/admins/customers/:id/create_credit")
  },
  coupons: {
    index: get("/api/admins/coupons"),
    create: post("/api/admins/coupons"),
    find: get("/api/admins/coupons/:id"),
    destroy: destroy("/api/admins/coupons/:id"),
    update: patch("/api/admins/coupons/:id")
  },
  appConfig: {
    show: get("/api/admins/app_config"),
    update: patch("/api/admins/app_config")
  },
  lots: {
    index: get("/api/admins/lots"),
    show: get("/api/admins/lots/:id"),
    update: patch("/api/admins/lots/:id")
  },
  trucks: {
    index: get("/api/admins/trucks")
  },
  markets: {
    index: get("/api/admins/markets")
  },
  locations: {
    index: get("/api/admins/locations"),
    show: get("/api/admins/locations/:id"),
    create: post("/api/admins/locations"),
    update: patch("/api/admins/locations/:id"),
    destroy: destroy("/api/admins/locations/:id"),
    summary: get("/api/admins/locations/summary"),
    endWork: post("/api/admins/locations/:id/end_work/:truckId"),
    startWork: post("/api/admins/locations/:id/start_work/:truckId"),
    resumeWork: post("/api/admins/locations/:id/resume_work/:truckId"),
    pauseWork: post("/api/admins/locations/:id/pause_work/:truckId")
  },
  menuTypes: {
    index: get("/api/admins/menu_types"),
    get: get("/api/admins/menu_types/:id"),
    create: post("/api/admins/menu_types"),
    update: patch("/api/admins/menu_types/:id"),
    destroy: destroy("/api/admins/menu_types/:id")
  },
  restaurants: {
    index: get("/api/admins/restaurants"),
    find: get("/api/admins/restaurants/:id"),
    create: post("/api/admins/restaurants"),
    update: put("/api/admins/restaurants/:id"),
    employees: {
      index: get("/api/admins/restaurants/:id/employees")
    },
    trucks: {
      index: get("/api/admins/restaurants/:id/trucks")
    }
  },
  cities: {
    index: get("/api/admins/cities")
  },
  foodTypeCategories: {
    index: get("/api/admins/food_type_categories"),
    show: get("/api/admins/food_type_categories/:id"),
    create: post("/api/admins/food_type_categories"),
    update: patch("/api/admins/food_type_categories/:id"),
    destroy: destroy("/api/admins/food_type_categories/:id"),
    foodTypes: {
      index: get(
        "/api/admins/food_type_categories/:foodTypeCategoryId/food_types"
      ),
      create: post(
        "/api/admins/food_type_categories/:foodTypeCategoryId/food_types"
      ),
      update: patch(
        "/api/admins/food_type_categories/:foodTypeCategoryId/food_types/:id"
      ),
      destroy: destroy(
        "/api/admins/food_type_categories/:foodTypeCategoryId/food_types/:id"
      )
    }
  },
  organizerPageHints: {
    index: get("/api/admins/organizer_page_hints"),
    show: get("/api/admins/organizer_page_hints/:id"),
    create: post("/api/admins/organizer_page_hints"),
    update: patch("/api/admins/organizer_page_hints/:id"),
    destroy: destroy("/api/admins/organizer_page_hints/:id")
  },
  orders: {
    index: get("/api/admins/orders"),
    show: get("/api/admins/orders/:id")
  },
  organizations: {
    index: get("/api/admins/organizations"),
    find: get("/api/admins/organizations/:id"),
    update: patch("/api/admins/organizations/:id"),
    create: post("/api/admins/organizations")
  },
  organizationAccounts: {
    find: get("/api/admins/organizations/:id/accounts/:organizerId"),
    update: patch("/api/admins/organizations/:id/accounts/:organizerId"),
    create: post("/api/admins/organizations/:id/accounts")
  }
}

export default api
