import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import Breadcrumbs from "common/components/Breadcrumbs"
import PageLoader from "common/components/PageLoader/index"
import PageContainer from "common/components/PageContainer"
import Typography from "@material-ui/core/Typography"
import ErrorBoundary from "common/components/ErrorBoundary"
import PageError from "common/components/PageError"
import { NavbarContext } from "admins/components/Navbar"

export default class Page extends React.Component {
  static propTypes = {
    breadcrumbs: PropTypes.func.isRequired,
    page: PropTypes.object,
    render: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    gutterTop: PropTypes.bool.isRequired
  }

  static defaultProps = {
    gutterTop: true,
    page: { isLoading: false }
  }

  componentDidMount() {
    const main = document.getElementById("page-main")
    if (main && main.scrollTo) {
      main.scrollTo(0, 0)
    }
    const { title } = this.props
    this.context.setHeaderComponent(
      <Typography variant="h6" color="inherit">
        {title}
      </Typography>
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.title !== this.props.title) {
      this.context.setHeaderComponent(
        <Typography variant="h6" color="inherit">
          {nextProps.title}
        </Typography>
      )
    }
  }

  componentWillUnmount() {
    this.context.setHeaderComponent(null)
  }

  render() {
    const { page, breadcrumbs, title, render, gutterTop } = this.props
    const helmet = (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    )
    if (page.isLoading || page.loading) {
      return (
        <div>
          {helmet}
          <Breadcrumbs
            sections={[
              {
                name: <span>&nbsp;</span>,
                url: <span>&nbsp;</span>
              }
            ]}
          />
          <PageLoader />
        </div>
      )
    }
    if (page.error) {
      return (
        <div>
          {helmet}
          <Breadcrumbs
            sections={[
              {
                name: <span>&nbsp;</span>,
                url: <span>&nbsp;</span>
              }
            ]}
          />
          <PageError
            message={page.error.message ? page.error.message : page.error}
          />
        </div>
      )
    }
    const sections = breadcrumbs(page)
    const hidden =
      sections &&
      sections[sections.length - 1] &&
      sections[sections.length - 1].hidden
    return (
      <ErrorBoundary message="Error Displaying the page. Try reloading the browser.">
        <div>
          {helmet}
          {!hidden && <Breadcrumbs sections={breadcrumbs(page)} />}
          <PageContainer gutterTop={gutterTop}>{render(page)}</PageContainer>
        </div>
      </ErrorBoundary>
    )
  }
}

Page.contextType = NavbarContext
