import React from "react"
import PropTypes from "prop-types"
import ReactErrorBoundary from "react-error-boundary"
import Rollbar from "common/utils/Rollbar"
import PageError from "common/components/PageError"
import { isFunction } from "lodash"

// error boundary can't catch error within itself,
// so we render everything in a separate component
class ErrorBoundaryProxy extends React.Component {
  static propTypes = {
    render: PropTypes.func.isRequired
  }

  render() {
    return this.props.render()
  }
}

export default class ErrorBoundary extends React.Component {
  static propTypes = {
    message: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
  }

  static defaultProps = {
    message: "Error Displaying Widget"
  }

  onError(error) {
    Rollbar.notify(`Boundary error: ${error.message}`, error)
    const logFunc = console.error || console.log // eslint-disable-line
    logFunc(error)
  }

  renderFallbackComponent = () => <PageError message={this.props.message} />

  render() {
    return (
      <ReactErrorBoundary
        onError={this.onError}
        FallbackComponent={this.renderFallbackComponent}
      >
        <ErrorBoundaryProxy
          render={() =>
            isFunction(this.props.children)
              ? this.props.children()
              : this.props.children
          }
        />
      </ReactErrorBoundary>
    )
  }
}
