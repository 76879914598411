import { createSelector } from "reselect"
import { initialState } from "./reducer"

/**
 * Direct selector to the menuTypePage state domain
 */
const selectMenuTypePageDomain = (state) =>
  state.get("menuTypePage") || initialState

/**
 * Other specific selectors
 */

/**
 * Default selector used by MenuTypePage
 */

const makeSelectMenuTypePage = () =>
  createSelector(selectMenuTypePageDomain, (substate) => substate.toJS())

export default makeSelectMenuTypePage
export { selectMenuTypePageDomain }
