import React from "react"
import PropTypes from "prop-types"
import { compose } from "redux"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"

const styles = (theme) => ({
  content: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3)
    },
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      paddingRight: theme.spacing(3)
    },
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      paddingBottom: theme.spacing(3)
    }
  },
  gutterTop: {
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(3)
    }
  }
})

class PageContainer extends React.PureComponent {
  render() {
    const { classes, gutterTop, fullWidth = false } = this.props
    return (
      <div
        className={`${classes.content} ${gutterTop ? classes.gutterTop : ""}`}
      >
        <div className={classNames({ container: !fullWidth })}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

PageContainer.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
  gutterTop: PropTypes.bool.isRequired
}

export default compose(withStyles(styles, { withTheme: true }))(PageContainer)
