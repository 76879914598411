import React from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form/immutable"
import InputAdornment from "@material-ui/core/InputAdornment"
import TextField from "@material-ui/core/TextField"
import _ from "lodash"

class PureFormMoneyField extends React.Component {
  static propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    description: PropTypes.any,
    placeholder: PropTypes.string,
    meta: PropTypes.object,
    InputProps: PropTypes.object
  }

  static defaultProps = {
    meta: {},
    margin: "normal"
  }

  constructor(props, context) {
    super(props, context)
    this.fixNormalize = /Android.*Chrome/.test(window.navigator.userAgent)
  }

  repositionCursor = (e) => {
    if (e.target.value.length === e.target.selectionStart) {
      e.persist()
      setTimeout(() => {
        e.target.selectionStart = e.target.value.length // reposition
      }, 10)
    }
  }

  onChange = (e) => {
    if (this.fixNormalize) {
      this.repositionCursor(e)
    }
    const { input } = this.props
    const looksLikeNumber =
      e.target.value.length === 0 ||
      /(^\d+$)|(^\d+.\d+$)|[,.]/.test(e.target.value)
    if (!looksLikeNumber) {
      e.preventDefault()
      return
    }
    input.onChange(e.target.value.toString())
  }

  onBlur = (e) => {
    const { input } = this.props
    input.onBlur(e)
    const intValue = Number(e.target.value)
    if (_.isNaN(intValue) || intValue < 0) {
      input.onChange(0)
    } else {
      input.onChange(Math.floor(intValue * 100))
    }
  }

  render() {
    const {
      input,
      label,
      description,
      placeholder,
      InputProps,
      meta: { touched, error },
      ...rest
    } = this.props
    const showError = touched && !!error
    let value = input.value // eslint-disable-line
    if (_.isNumber(value)) {
      value /= 100
    }
    return (
      <TextField
        error={showError}
        placeholder={placeholder}
        label={label}
        value={value}
        onChange={this.onChange}
        name={input.name}
        type="text"
        helperText={showError ? error : description}
        margin="normal"
        InputProps={{
          ...InputProps,
          inputProps: {
            onBlur: this.onBlur
          },
          startAdornment: <InputAdornment position="start">$</InputAdornment>
        }}
        {...rest}
      />
    )
  }
}

export default class FormMoneyField extends React.Component {
  render() {
    return <Field {...this.props} component={PureFormMoneyField} />
  }
}
