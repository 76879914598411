/*
 *
 * MenuTypePage actions
 *
 */

import { createFormAction } from "common/pkg/redux-form-saga"
import { LOAD_PAGE, PAGE_LOADED } from "./constants"

export function loadPage(id) {
  return {
    type: LOAD_PAGE,
    payload: { id }
  }
}

export function pageLoaded({ menuType }) {
  return {
    type: PAGE_LOADED,
    payload: { menuType }
  }
}

export const formSubmit = createFormAction("UPDATE_FOOD_TYPE")
