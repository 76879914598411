import _ from "lodash"

const messageFromResponse = (
  response,
  alternativeMessage = "Some error occurred"
) => {
  let message
  if (_.isString(response)) {
    message = response
  } else if (response.message) {
    message = response.message // eslint-disable-line
  } else if (_.isArray(response)) {
    message = response
      .map((e) =>
        _.endsWith(e.message, ".") || _.endsWith(e.message, "!")
          ? e.message
          : `${e.message}.`
      )
      .join(" ")
  } else {
    const messagePresent =
      response.err &&
      response.data &&
      ((response.data.errors && response.data.errors[0]) || response.data.error)

    message = `${
      messagePresent
        ? response.data.errors ||
          [response.data.error]
            .map((error) =>
              _.endsWith(error, ".") || _.endsWith(error, "!")
                ? error
                : `${error}.`
            )
            .join(" ")
        : `${alternativeMessage}.`
    }`
  }
  return message
}

export default messageFromResponse
