import React from "react"
import { isArray, camelCase } from "lodash"
import { FORM_ERROR } from "final-form"
import { FetchResult, ApolloError, MutationResult } from "@apollo/client"
import Rollbar from "common/utils/Rollbar"

import { getSystemError } from "./extendApolloHooks"

interface Error {
  key: string | null
  message: string | null
  fullMessage: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
}

interface MutationBody {
  errors: Error[] | null
  [key: string]: unknown
}

interface SubmittedValuesObject {
  [name: string]: unknown
}

type ErrorHash<Obj extends Record<string, unknown>> = {
  [k in keyof Obj | "FINAL_FORM/form-error"]?:
    | string
    | null
    | React.ReactElement
}

function valuesToWhitelist(values: SubmittedValuesObject): string[] {
  return Object.keys(values)
}

export default function getMutationErrors<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  MutationData extends Record<string, any>
>(
  response: FetchResult<MutationData> | MutationResult<MutationData>,
  submittedValues?: Record<string, unknown> | Array<string>
): ErrorHash<MutationBody> | undefined {
  const errorsHash: ErrorHash<MutationBody> = {}
  if (!response) {
    return {
      [FORM_ERROR]: "Unknown error"
    }
  }
  if (!response.data) {
    const error =
      (response as any).error ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((response as any).errors as ApolloError) ||
      new ApolloError({ errorMessage: "Unknown error" })
    if (errorsHash[FORM_ERROR]) {
      errorsHash[FORM_ERROR] += `. ${getSystemError(error)}`
    } else {
      errorsHash[FORM_ERROR] = getSystemError(error)
    }
    Rollbar.notify(errorsHash[FORM_ERROR]!)
    return errorsHash
  }
  const data = response.data as { [key: string]: MutationBody | null }
  const key = Object.keys(data)[0]
  const keyData = data[key]
  const mutationData = keyData as MutationBody
  if (!mutationData) return { [FORM_ERROR]: "Unknown error" }
  if (!mutationData.errors) return undefined
  let whiteList: string[]
  if (isArray(submittedValues)) {
    whiteList = submittedValues as string[]
  } else if (submittedValues) {
    whiteList = valuesToWhitelist(submittedValues as Record<string, unknown>)
  } else {
    whiteList = []
  }
  ;(mutationData.errors || []).forEach((error) => {
    const errorKey = error.key ? camelCase(error.key) : FORM_ERROR
    if (whiteList.includes(errorKey)) {
      errorsHash[errorKey] = error.message
    } else if (errorsHash[FORM_ERROR]) {
      errorsHash[FORM_ERROR] += `. ${error.fullMessage ?? error.message}`
    } else {
      errorsHash[FORM_ERROR] = error.fullMessage ?? error.message
    }
  })
  return errorsHash
}

export function getMutationErrorData<ErrorData>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: FetchResult<Record<string, any>>,
  errorKey: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): null | ErrorData {
  if (!response.data) {
    return null
  }
  const data = response.data as { [key: string]: MutationBody | null }
  const key = Object.keys(data)[0]
  const keyData = data[key]
  const mutationData = keyData as MutationBody
  if (!mutationData) return null
  if (!mutationData.errors) return null

  const matchingError = mutationData.errors.find((e) => e.key === errorKey)

  if (!matchingError || !matchingError.data) return null

  return JSON.parse(matchingError.data) as ErrorData
}
