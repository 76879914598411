/* tslint:disable */
/* eslint-disable */
// @ts-ignore
import * as Types from "types/global-graphql-types"

import { DocumentNode } from "graphql"
import * as Apollo from "@apollo/client"
import * as ApolloReactHooks from "common/utils/extendApolloHooks"
const defaultOptions = {} as const
export type MetabaseMakePublicLinkVariables = Types.Exact<{
  id: Types.Scalars["Int"]
  type: Types.Scalars["String"]
  params: Types.Scalars["Json"]
}>

export type MetabaseMakePublicLink = {
  __typename: "Root"
  metabaseMakePublicLink: {
    __typename: "MetabaseMakePublicLinkPayload"
    url: string | null
    errors: Array<{
      __typename: "UserError"
      key: string | null
      message: string | null
      fullMessage: string | null
    }> | null
  } | null
}

export const MetabaseMakePublicLinkDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "MetabaseMakePublicLink" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "params" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Json" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "metabaseMakePublicLink" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "type" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "params" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "params" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "url" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "errors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "key" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fullMessage" }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown) as DocumentNode
export type MetabaseMakePublicLinkMutationFn = Apollo.MutationFunction<
  MetabaseMakePublicLink,
  MetabaseMakePublicLinkVariables
>

/**
 * __useMetabaseMakePublicLink__
 *
 * To run a mutation, you first call `useMetabaseMakePublicLink` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMetabaseMakePublicLink` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [metabaseMakePublicLink, { data, loading, error }] = useMetabaseMakePublicLink({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useMetabaseMakePublicLink(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MetabaseMakePublicLink,
    MetabaseMakePublicLinkVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    MetabaseMakePublicLink,
    MetabaseMakePublicLinkVariables
  >(MetabaseMakePublicLinkDocument, options)
}
export type MetabaseMakePublicLinkHookResult = ReturnType<
  typeof useMetabaseMakePublicLink
>
export type MetabaseMakePublicLinkMutationResult = Apollo.MutationResult<MetabaseMakePublicLink>
export type MetabaseMakePublicLinkMutationOptions = Apollo.BaseMutationOptions<
  MetabaseMakePublicLink,
  MetabaseMakePublicLinkVariables
>
