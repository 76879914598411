import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router/immutable"
import queryString from "query-string"
import hoistNonReactStatics from "hoist-non-react-statics"
import PropTypes from "prop-types"

const withMarketParam = (WrappedComponent) => {
  const klass = class extends React.PureComponent {
    static propTypes = {
      dispatch: PropTypes.func.isRequired
    }

    query = () => queryString.parse(this.props.location.search)

    onChangeMarketId = (marketId) => {
      const newParams = { ...this.query(), marketId }
      if (!marketId) delete newParams.marketId
      const newQuery = queryString.stringify(newParams)
      this.props.dispatch(push(`${this.props.location.pathname}?${newQuery}`))
    }

    render() {
      const query = this.query()
      return (
        <WrappedComponent
          marketId={query.marketId ? parseInt(query.marketId, 10) : null}
          onChangeMarketId={this.onChangeMarketId}
          {...this.props}
        />
      )
    }
  }
  klass.displayName = `withMarketParam(${
    WrappedComponent.displayName || WrappedComponent.name
  })`
  hoistNonReactStatics(klass, WrappedComponent)
  return connect()(klass)
}

export default withMarketParam
