import { createSelector } from "reselect"
import { initialState } from "./reducer"

/**
 * Direct selector to the buildersPage state domain
 */
const selectBuildersPageDomain = (state) =>
  state.get("buildersPage") || initialState

/**
 * Other specific selectors
 */

/**
 * Default selector used by BuildersPage
 */

const makeSelectBuildersPage = () =>
  createSelector(selectBuildersPageDomain, (substate) => substate.toJS())

export default makeSelectBuildersPage
export { selectBuildersPageDomain }
