let webHost

if (process.env.NODE_ENV === "production") {
  webHost = "https://www.bestfoodtrucks.com"
} else if (process.env.NODE_ENV === "staging") {
  webHost = "https://staging.bestfoodtrucks.com"
} else {
  webHost = "http://customers.bft.com"
}

const config = {
  host: process.env.REACT_APP_API_HOST || "http://api.bft.com",
  cdnHost: process.env.CDN_HOST || "/",
  webHost
}

if (config.cdnHost[config.cdnHost.length - 1] !== "/") {
  config.cdnHost = `${config.cdnHost}/`
}

module.exports = config
