import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { connect } from "react-redux"
import { compose } from "redux"
import Typography from "@material-ui/core/Typography"
import UiMultipleAutocompleteSelectField from "../../../../common/components/UiMultipleAutocompleteSelectField"
import focusOnError from "../../../../common/utils/focusOnError"
import { Form } from "react-final-form"
import { useLotsOptionsQuery } from "./types/LotOptionsQuery"

type Props = {
  onClose: () => void
  open: boolean
  onSubmit: (args: { lotIds: number[] }) => void
}

const validate = ({ lotIds }: { lotIds: number[] }) => {
  const errors: Record<string, string> = {}
  if (!lotIds) {
    errors.lotIds = "Required"
  }
  return errors
}

const MakeMetabaseLinkDialog = ({ onClose, open, onSubmit }: Props) => {
  const { data } = useLotsOptionsQuery({
    fetchPolicy: "cache-first",
    skip: !open
  })
  const lots = data?.lots?.records || []
  const lotOptions = lots.map((l) => ({
    value: l.id,
    label: l.name
  }))
  return (
    <Dialog open={open} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        decorators={[focusOnError]}
        render={({ handleSubmit, submitting, values }) => (
          <>
            <DialogTitle>Health Dashboard Link</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                This tool generated a public link to the Lot Health metabase
                dashboard.
              </Typography>
              <br />
              <UiMultipleAutocompleteSelectField
                name="lotIds"
                label="Lots"
                multiple
                fullWidth
                options={lotOptions}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  handleSubmit()
                }}
              >
                Generate a link
              </Button>
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  )
}

export default compose(connect())(MakeMetabaseLinkDialog)
