import React from "react"
import TablePagination from "@material-ui/core/TablePagination"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import TablePaginationActions from "common/components/TablePaginationActions"
import { isNaN } from "lodash"

const styles = {
  toolbarcenter: {
    justifyContent: "center"
  },
  toolbarright: {
    justifyContent: "flex-end"
  },
  toolbarleft: {
    justifyContent: "flex-start"
  },
  spacer: {
    display: "none"
  }
}

const KEYS = {
  LEFT: 37,
  RIGHT: 39
}

class PagePagination extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onOffsetChange: PropTypes.func,
    onPageChange: PropTypes.func,
    onPerPageChange: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired,
    offset: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    perPageOptions: PropTypes.array,
    hotkeys: PropTypes.bool,
    component: PropTypes.string,
    align: PropTypes.oneOf(["right", "left", "center"])
  }

  static injectedProps = {
    offset: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    onChangePerPage: PropTypes.func.isRequired,
    onOffsetChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    hotkeys: false,
    perPageOptions: [10, 50, 100],
    align: "center"
  }

  handleKeyDown = (event) => {
    if (event.target.nodeName === "INPUT") return
    switch (event.keyCode) {
      case KEYS.LEFT:
        this.previousPage()
        event.preventDefault()
        event.stopPropagation()
        break
      case KEYS.RIGHT:
        this.nextPage()
        event.preventDefault()
        event.stopPropagation()
        break
      default:
        break
    }
  }

  componentDidMount() {
    if (this.props.hotkeys) {
      document.addEventListener("keydown", this.handleKeyDown)
    }
  }

  componentWillUnmount() {
    if (this.props.hotkeys) {
      document.removeEventListener("keydown", this.handleKeyDown)
    }
  }

  nextPage = () => {
    const page = this.page() + 1
    if (isNaN(page) || page < 0) {
      return
    }
    this.handleChangePage(null, page)
  }

  previousPage = () => {
    const page = this.page() - 1
    if (isNaN(page) || page < 0) {
      return
    }
    this.handleChangePage(null, page)
  }

  handleChangePage = (_, page) => {
    if (this.props.onOffsetChange) {
      const offset = page * this.props.perPage
      this.props.onOffsetChange(offset)
    } else {
      this.props.onPageChange(page)
    }
  }

  handleChangeShipmentsPerPage = (e) => {
    this.props.onPerPageChange(e.target.value)
  }

  page = () => {
    const { offset, perPage } = this.props
    return Math.floor(offset / perPage)
  }

  render() {
    const {
      total,
      perPage,
      label,
      classes,
      perPageOptions,
      component,
      align,
      hideIfPossible
    } = this.props
    if (hideIfPossible && total <= perPage) {
      return null
    }
    return (
      <TablePagination
        count={total}
        page={this.page()}
        rowsPerPage={perPage}
        classes={{
          toolbar: classes[`toolbar${align}`],
          spacer: classes.spacer
        }}
        labelRowsPerPage={label}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeShipmentsPerPage}
        rowsPerPageOptions={perPageOptions}
        ActionsComponent={
          this.props.hotkeys ? TablePaginationActions : undefined
        }
        component={component}
      />
    )
  }
}

export default withStyles(styles)(PagePagination)
