import React from "react"
import CopyMenuDialog from "./index"
import { connect } from "react-redux"
import executeMutation from "common/utils/executeMutation"
import confirmDialog from "admins/utils/confirmDialog"
import { compose } from "redux"
import { graphql } from "@apollo/client/react/hoc"
import gql from "graphql-tag"
import { push } from "connected-react-router/immutable"

const mutation = gql`
  mutation menuCopy($fromMenuId: Int!, $toMenuId: Int!) {
    menuCopy(fromMenuId: $fromMenuId, toMenuId: $toMenuId) {
      errors {
        fullMessage
        key
        message
      }
      menu {
        id
        restaurant {
          id
        }
      }
    }
  }
`

const withMenuCopy = graphql(mutation, {
  props: ({ mutate }) => ({
    menuCopy: (variables) =>
      mutate({
        variables
      })
  })
})

const CopyMenuDialogButton = ({ children, dispatch, menuCopy }) => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = async ({ fromId, toId }) => {
    if (!fromId) {
      throw new Error("From Id is missing")
    }
    if (!toId) {
      throw new Error("To Id is missing")
    }
    const values = {
      fromMenuId: parseInt(fromId, 10),
      toMenuId: parseInt(toId, 10)
    }
    const data = await executeMutation({
      confirmation: confirmDialog(
        "Are you sure?",
        "All items are going to be copied. This make take some time."
      ),
      dispatch,
      successMessage:
        "Items copy was initiated, refreshed the page in a minute",
      values,
      mutation: menuCopy,
      swallowExceptions: false
    })
    setOpen(false)
    const {
      menuCopy: { menu }
    } = data
    dispatch(push(`/restaurants/${menu.restaurant.id}/menus/${menu.id}`))
  }

  return (
    <>
      {React.cloneElement(children, {
        onClick: handleClickOpen
      })}
      <CopyMenuDialog onClose={handleClose} open={open} onSubmit={onSubmit} />
    </>
  )
}

export default compose(connect(), withMenuCopy)(CopyMenuDialogButton)
