/**
 *
 * MenuTypePage
 *
 */

import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { compose } from "redux"

import injectSaga from "admins/utils/injectSaga"
import injectReducer from "admins/utils/injectReducer"
import makeSelectMenuTypePage from "./selectors"
import { loadPage } from "./actions"
import reducer from "./reducer"
import saga from "./saga"
import MenuTypeForm from "./MenuTypeForm"
import MenuTypesPage from "admins/containers/MenuTypesPage"
import Page from "admins/components/Page/index"

class MenuTypePage extends React.Component {
  static breadcrumbs = ({ menuType }) =>
    MenuTypesPage.breadcrumbs().concat([
      {
        name: menuType && menuType.id ? menuType.name : "New",
        url: `/builders/menu-types/${
          menuType && menuType.id ? menuType.id : "new"
        }`
      }
    ])
  // eslint-disable-line react/prefer-stateless-function
  componentDidMount() {
    const { id } = this.props
    this.props.dispatch(loadPage(id))
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.id !== nextProps.id) {
      this.props.dispatch(loadPage(nextProps.id))
    }
  }

  breadcrumbs = () =>
    MenuTypePage.breadcrumbs({
      menuType: this.props.page.menuType
    })

  renderContent = ({ menuType }) => (
    <div className="row">
      <div className="col">
        <MenuTypeForm
          initialValues={{
            ...menuType,
            minCateringBudgetCents: menuType.minCateringBudget.value
          }}
        />
      </div>
    </div>
  )

  render() {
    return (
      <Page
        breadcrumbs={this.breadcrumbs}
        page={this.props.page}
        render={this.renderContent}
        title="Menu type"
      />
    )
  }
}

MenuTypePage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired,
  id: PropTypes.string
}

const mapStateToProps = createStructuredSelector({
  page: makeSelectMenuTypePage(),
  id: (
    _,
    {
      match: {
        params: { id }
      }
    }
  ) => id
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({
  key: "menuTypePage",
  reducer
})
const withSaga = injectSaga({ key: "menuTypePage", saga })

export default compose(withReducer, withSaga, withConnect)(MenuTypePage)
